import React, { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

import MyContext from "../context/context";

// MUI
import { AppBar, BottomNavigation, BottomNavigationAction, Box, Button, CircularProgress, Dialog, DialogContent, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Toolbar, Typography } from '@mui/material'
import { AutoAwesome, CoPresent, Face, PowerSettingsNew, Receipt, Sync } from '@mui/icons-material'

export default function SidebarUser(){
    const api = useContext(MyContext)
    const [open, setOpen] = useState('')
    const [action, setAction] = useState('')
    const auth = Cookies.get('authuser')
    useEffect(() => {
        if(auth === undefined){
            navi('/login-student')
        }
    }, [auth])

    const navi = useNavigate()
    const link = [
        {name: 'Spyware', link: '/student/home', icon: <AutoAwesome />},
        {name: 'Payment', link: '/student/payment', icon: <Receipt />},
        {name: 'Attendance', link: '/student/attendance', icon: <CoPresent />},
        {name: 'Profile', link: '/student/home', icon: <Face />}
    ]

    const [value, setValue] = React.useState(0);
    useEffect(() => {
        const storedValue = localStorage.getItem('activeButtonIndex');
        if (storedValue !== null) {
          setValue(parseInt(storedValue));
        }
    }, []);
    const change_btn_color = (event, newValue) => {
        setValue(newValue)
        localStorage.setItem('activeButtonIndex', newValue);
    }

    const logout = () => {
        setAction('loggingout')
        var formData = new FormData()
        formData.append('logout', '')
        fetch(api.api.student, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setAction('')
            if(data.status === true){
                navi('/login-student', {replace: true})
            }
        })
    }

    return(
        <Box component='nav' sx={{marginBottom: 10}}>
            <Dialog open={open === 'logout'} fullWidth maxWidth='xs' onClose={() => setOpen('')} >
                <DialogContent>
                    <Box mt={2} mb={2} >
                        <Typography fontWeight='bold' fontSize={25} color={'warning.main'} mb={1} >Do you want to logout?</Typography>
                        <Typography variant='caption'>Note: This process will redirect you to the login page.</Typography>

                        <Box textAlign='center' mt={2} >
                            <Button color='warning' variant='contained' sx={{borderRadius: 0, boxShadow: 'none'}} disabled={action === 'loggingout' ? true : false} onClick={() => logout()} >{action === 'loggingout' ? <CircularProgress color='inherit' size={24} /> : 'YES, LOGOUT'}</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            <AppBar position="fixed" color="info" >
                <Toolbar>
                    <Typography color={'white'} fontSize={20} className="orbitron" fontWeight={600} >#SPYWARE</Typography>
                    <Typography sx={{ flexGrow: 1 }} />
                    <IconButton color="inherit" onClick={() => window.location.reload() } >
                        <Sync />
                    </IconButton>
                    <IconButton color="inherit" onClick={() => setOpen('logout')} >
                        <PowerSettingsNew />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Paper sx={{ display: {xs: 'block', md: 'none'}, position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000 }} elevation={3}>
                <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    change_btn_color(event, newValue)
                }}
                >
                    { link.map((row, i) =>
                    <BottomNavigationAction color="info" label={row.name} icon={row.icon} key={i} onClick={() => navi(row.link)} />
                    ) }
                </BottomNavigation>
            </Paper>
            
            <Drawer
            variant="permanent"
            sx={{
                display: { xs: 'none', sm: 'block' },
            }}
            PaperProps={{style: {width: '256px'}}}
            >
                <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} height={63} borderBottom={'thin solid'} borderColor={'primary.main'} >
                    <Typography textAlign={'center'} color={'primary'} fontSize={25} mr={1} className="orbitron" fontWeight={600} >#SPYWARE</Typography>
                </Stack>
                <List>
                    { link.map((row, i) =>
                    <ListItem key={i} >
                        <ListItemButton onClick={() => navi(row.link)} >
                            <ListItemIcon>{row.icon}</ListItemIcon>
                            <ListItemText>{row.name}</ListItemText>
                        </ListItemButton>
                    </ListItem> ) }
                </List>
            </Drawer><Outlet/>
        </Box>
    );
}