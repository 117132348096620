import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

/* MUI */
import { Alert, Box, Container, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { PendingActions, Person, School, WorkspacePremium } from '@mui/icons-material'
import axios from 'axios';

export default function DashboardController({apiadmin}){
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`

    const [filterday, setFilterday] = useState(formattedDate)
    useEffect(() => {
        dashboard(filterday)
    }, [filterday])

    const [dash, setDash] = useState([])
    const [student, setStudent] = useState([])
    const dashboard = (filterday) => {
        var formData = new FormData()
        formData.append('dashboard', '')
        formData.append('day', filterday)
        axios.post(apiadmin, formData)
        .then(function(res){
            setDash(res.data.dash.data)
            if(res.data.student.status === false){
                setStudent(res.data.student)
            } else {
                setStudent(res.data.student.data)
            }
        })
    }

    return(
        <Box className='content' >
        <Container >
            <Grid container spacing={2} mt={3} >
                <Grid item xs={6} md={4}>
                    <Box padding={2} component={Paper} textAlign='center' >
                        <Person sx={{fontSize: 70, color: '#FF4F00'}} />
                        <Box sx={{padding: 0.1, background: 'red'}} />

                        <Stack mt={1} direction='row' justifyContent='space-between' alignItems='center'>
                            <Typography color='gray' fontSize={12} letterSpacing={1} >STUDENTS</Typography>
                            <Typography color='red' fontWeight='bold' fontSize={20} letterSpacing={3} >{dash.active_student}</Typography>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Box padding={2} component={Paper} textAlign='center' >
                        <WorkspacePremium sx={{fontSize: 70, color: '#007FFF'}} />
                        <Box sx={{padding: 0.1, background: 'red'}} />

                        <Stack mt={1} direction='row' justifyContent='space-between' alignItems='center'>
                            <Typography color='gray' fontSize={12} letterSpacing={1} >ISSUED</Typography>
                            <Typography color='red' fontWeight='bold' fontSize={20} letterSpacing={3} >{dash.issued_certificate}</Typography>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box padding={2} component={Paper} textAlign='center' >
                        <School sx={{fontSize: 70, color: '#03C03C'}} />
                        <Box sx={{padding: 0.1, background: 'red'}} />

                        <Stack mt={1} direction='row' justifyContent='space-between' alignItems='center'>
                            <Typography color='gray' fontSize={12} letterSpacing={1} >FRANCHISE</Typography>
                            <Typography color='red' fontWeight='bold' fontSize={20} letterSpacing={3} >{dash.franchise}</Typography>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={0}>
                <Grid item xs={12} md={12}>
                    <Box textAlign={'center'} mb={2} >
                        <NavLink to={'/controller/workshop'}>Workshop data</NavLink>
                    </Box>
                    <Box component={Paper} textAlign='center' height={450} >
                        <Stack p={1.5} direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{bgcolor: '#F5F5F5'}} >
                            <Typography>New students</Typography>
                            <TextField type='date' size='small' value={filterday} onChange={(event) => setFilterday(event.target.value)} />
                        </Stack>
                        <Box p={1.5}>
                        <TableContainer sx={{maxHeight: 350}} >
                            { student.status === false ? <Alert severity='warning' sx={{mt: 2}} >{student.msg}</Alert> :
                            <Table>
                                <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell align="left">Name</TableCell>
                                    <TableCell align="left">Guardian</TableCell>
                                    <TableCell align="left">Mobile</TableCell>
                                    <TableCell align="left">Gender</TableCell>
                                    <TableCell align="right">Join date</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {student.map((row, i) => (
                                    <TableRow
                                    key={i}>
                                    <TableCell align="left">{i+1}</TableCell>
                                    <TableCell align="left"><NavLink style={{color: 'darkorange'}} to={`/controller/student-info/${row.studentid}`}>{row.name}</NavLink></TableCell>
                                    <TableCell align="left">{row.guardian}</TableCell>
                                    <TableCell align="left"><NavLink style={{color: 'blue'}} to={`tel: ${row.mobile}`}>{row.mobile}</NavLink></TableCell>
                                    <TableCell align="left">{row.gender}</TableCell>
                                    <TableCell align="right">{row.joindate}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table> }
                        </TableContainer>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
        </Box>
    );
}