import React, { useContext, useEffect, useRef, useState } from "react";
import {  } from 'react-router-dom'
import { BrowserMultiFormatReader } from '@zxing/library';

import PublicHeader from "../../public/header";
import MyContext from "../../context/context";

// MUI
import { Backdrop, Box, Card, CardContent, Chip, CircularProgress, Container, Dialog, DialogContent, Divider, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import {  } from '@mui/icons-material'
import axios from "axios";

export default function Scanner(){
    const api = useContext(MyContext)
    const [open, setOpen] = useState('')
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`

    const [working, setWorking] = useState(false)

    const videoRef = useRef(null);

    var scanning = false;
    useEffect(() => {
        start_scanning()
    }, [])

    const start_scanning = () => {
        const codeReader = new BrowserMultiFormatReader();
        codeReader
        .decodeFromVideoDevice(undefined, videoRef.current, (result) => {
            if (result && !scanning ) {
                make_attendance(result.text)
                scanning = true
            }
        })
        .catch(err => {
            console.error('Error accessing video stream:', err);
        });
        return () => {
            codeReader.reset();
        };
    }

    const [attendance, setAttendance] = useState([])
    useEffect(() => {
        attendance_list()
    }, [])
    const attendance_list = () => {
        axios.get(`${api.api.global}?attendance-list=${formattedDate}`)
        .then(function(res){
            if(res.data.status === false){
                setAttendance(res.data)
            } else {
                setAttendance(res.data.data)
            }
        })
    }

    const [atres, setAtres] = useState([])
    const make_attendance = (qr) => {
        setWorking(true)
        var formData = new FormData()
        formData.append('make-attendance', qr)
        axios.post(api.api.global, formData)
        .then(function(res){
            setWorking(false)
            setOpen('atalert')
            setAtres(res.data)
            res.data.status === true && attendance_list()
            setTimeout(() => {
                close_alert();
            }, 2500);            
        })
    }

    const close_alert = () => {
        scanning = false
        start_scanning()
        setOpen('')
    }

    document.addEventListener('keypress', function(event) {
        if (event.key === 'Enter') {
            scanning = false
            start_scanning()
            setOpen('')
        }
    });

    return(
        <Box><PublicHeader text={'Attendance scanner'} />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={working}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog open={open === 'atalert' ? true : false} fullWidth maxWidth='md' onClose={() => close_alert()} >
                <DialogContent>
                    <audio autoPlay >
                        <source src="/images/done.mp3" type="audio/mpeg" />
                    </audio>
                    <Box pt={1} pb={1} >
                        <Box textAlign={'center'}>
                            <Typography className="cert-font" fontSize={20} color={'#007FFF'} fontWeight={600} letterSpacing={2} >|| ATTENDANCE ||</Typography>
                        </Box>
                        <Box textAlign={'center'}>
                            <img src="/images/success.gif" alt="Attendance succeed" style={{width: 300, height: 300}} />
                            <Typography color={'gray'} fontSize={12} >Press 'Enter Key &#9166;' to close this window.</Typography>
                        </Box>
                        <Box mt={2} >
                            <Typography fontSize={30} fontWeight={600} color={atres.status === true ? `#007FFF` : '#FF4F00' } >{atres.msg}</Typography>
                            <Typography color={'#007FFF'} mt={2} fontSize={14} >_Thank you for being the family of spyware.</Typography>
                            <Typography color={'#007FFF'} fontSize={14} >_We assure you that you are in the right place.</Typography>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            <Container>
                <Grid container mt={3} spacing={2} >
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <video ref={videoRef} style={{width: '100%', height: '100%', borderRadius: 10}} ></video>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <Stack mb={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography fontSize={14} color={'#007FFF'} >Present</Typography>
                                    <Typography fontWeight={500} fontSize={18} color={'#007FFF'} >{attendance.length}</Typography>
                                </Stack><Divider/>
                                <TableContainer sx={{height: 402}} >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>#</TableCell>
                                                <TableCell align="left">Name</TableCell>
                                                <TableCell align="left">In</TableCell>
                                                <TableCell align="right">Out</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {attendance.status != false && attendance.map((row, i) => (
                                            <TableRow
                                                key={i}
                                                >
                                                <TableCell component="th" scope="row">
                                                    {(attendance.length)-i}
                                                </TableCell>
                                                <TableCell align="left">{row.name}</TableCell>
                                                <TableCell align="left"><Chip label={new Date(row.at_in).toLocaleTimeString(undefined, {hour: '2-digit', minute: '2-digit', hour12: true})} color="success" /></TableCell>
                                                <TableCell align="right"><Chip label={row.at_out ? new Date(row.at_out).toLocaleTimeString(undefined, {hour: '2-digit', minute: '2-digit', hour12: true}) : 'Waiting'} color="warning" /></TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}