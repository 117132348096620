import React, { useEffect, useState } from "react";
import {  } from 'react-router-dom'

// MUI
import { Alert, Box, Container, useMediaQuery, useTheme } from '@mui/material'
import {  } from '@mui/icons-material'
import axios from "axios";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

export default function GalleryPublic({apipublic, gallery}){

    const [galleryimg, setGalleryimg] = useState([])
    useEffect(() => {
        loadgallery()
    }, [])

    const loadgallery = () => {
        const formData = new FormData()
        formData.append('get-gallery', '')
        axios.post(apipublic, formData)
        .then(function(res){
            res.data.status === false ? setGalleryimg(res.data) : setGalleryimg(res.data.data)
        })
    }

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return(
        <Box>
            <Container>
                <Box>{ galleryimg.status === false ? <Alert severity="warning" sx={{mt: 5}} >We are uploading images. Please wait to see them.</Alert> :
                    <ImageList cols={isSmallScreen ? 2 : 4} variant="masonry" >
                        {galleryimg.map((item) => (
                            <ImageListItem key={item.img} >
                            <img
                                srcSet={`${gallery+item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                src={`${gallery+item.img}?w=164&h=164&fit=crop&auto=format`}
                                alt={item.title}
                                loading="lazy"
                                style={{borderRadius: 10}}
                            />
                            </ImageListItem>
                        ))}
                    </ImageList>}
                </Box>
            </Container>            
        </Box>
    );
}