import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import MyContext from './../context/context'

/* MUI */
import { Alert, Box, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { NotificationsActive, PendingActions, Person } from '@mui/icons-material'
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";

export default function DashboardFranchise({apiglobal}){
    const api = useContext(MyContext)

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`

    const [filterday, setFilterday] = useState(formattedDate)
    useEffect(() => {
        dashboard(filterday)
    }, [filterday])

    const [dash, setDash] = useState([])
    const [student, setStudent] = useState([])
    const dashboard = (filterday) => {
        var formData = new FormData()
        formData.append('dashboard', '')
        formData.append('day', filterday)
        axios.post(apiglobal, formData)
        .then(function(res){
            setDash(res.data.dash.data)
            if(res.data.student.status === false){
                setStudent(res.data.student)
            } else {
                setStudent(res.data.student.data)
            }
        })
    }

    const [notpaid, setNotpaid] = useState([])
    useEffect(() => {
        not_paid()
    }, [])
    const not_paid = () => {
        axios.get(`${api.api.global}?fee-not-payed-list`)
        .then(function(res){
            if(res.data.status === true){
                setNotpaid(res.data.data)
            } else {
                setNotpaid(res.data)
            }
        })
    }

    const [paymentmonth, setPaymentmonth] = useState(currentDate.getMonth() + 1)
    const [premonthamt, setPremonthamt] = useState(0)
    const [currentmonthamt, setCurrentmonthamt] = useState(0)
    useEffect(() => {
        payments_by_months(paymentmonth)
    }, [paymentmonth])
    const payments_by_months = (paymentmonth) => {
        axios.get(`${api.api.global}?payment-by-month=${paymentmonth}`)
        .then(function(res){
            res.data.pre_payment.status === true ? setPremonthamt(res.data.pre_payment.data.payments) : setPremonthamt(0)
            res.data.payment.status === true ? setCurrentmonthamt(res.data.payment.data.payments) : setCurrentmonthamt(0)
        })
    }

    const monthname = (paymentmonth) => {
        const date = new Date()
        date.setMonth(paymentmonth - 1)
        return date.toLocaleDateString('default', {month: 'long'})
    }

    const currentmonth = monthname(paymentmonth)
    const premonth = monthname(paymentmonth - 1)

    const data = [
        {
          name: premonth,
          uv: premonthamt,
          pv: premonthamt,
          amt: premonthamt,
        },
        {
          name: currentmonth,
          uv: currentmonthamt,
          pv: currentmonthamt,
          amt: currentmonthamt,
        },
      ];

    return(
        <Box className='content' >
        <Container >
            <Grid container spacing={2} mt={3} >
                <Grid item xs={6} md={4}>
                    <Box padding={2} component={Paper} textAlign='center' >
                        <Person sx={{fontSize: 70, color: '#FF4F00'}} />
                        <Box sx={{padding: 0.1, background: 'red'}} />

                        <Stack mt={1} direction='row' justifyContent='space-between' alignItems='center'>
                            <Typography color='gray' fontSize={12} letterSpacing={1} >STUDENTS</Typography>
                            <Typography color='red' fontWeight='bold' fontSize={20} letterSpacing={3} >{dash.student}</Typography>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Box padding={2} component={Paper} textAlign='center' >
                        <NotificationsActive sx={{fontSize: 70, color: '#007FFF'}} />
                        <Box sx={{padding: 0.1, background: 'red'}} />

                        <Stack mt={1} direction='row' justifyContent='space-between' alignItems='center'>
                            <Typography color='gray' fontSize={12} letterSpacing={1} >ACTIVE</Typography>
                            <Typography color='red' fontWeight='bold' fontSize={20} letterSpacing={3} >{dash.active_student}</Typography>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box padding={2} component={Paper} textAlign='center' >
                        <PendingActions sx={{fontSize: 70, color: '#FF4F00'}} />
                        <Box sx={{padding: 0.1, background: 'red'}} />

                        <Stack mt={1} direction='row' justifyContent='space-between' alignItems='center'>
                            <Typography color='gray' fontSize={12} letterSpacing={1} >PENDING</Typography>
                            <Typography color='red' fontWeight='bold' fontSize={20} letterSpacing={3} >{dash.pending_student}</Typography>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={0} >
                <Grid item xs={12} md={12}>
                    <Box padding={2} component={Paper} textAlign='center' >
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography color={'gray'} >Payment report</Typography>
                            <FormControl size='small' >
                                <InputLabel id="demo-simple-select-label">Month</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={paymentmonth}
                                    label="Month"
                                    onChange={(event) => setPaymentmonth(event.target.value)}
                                >
                                    <MenuItem value={1}>January</MenuItem>
                                    <MenuItem value={2}>February</MenuItem>
                                    <MenuItem value={3}>March</MenuItem>
                                    <MenuItem value={4}>April</MenuItem>
                                    <MenuItem value={5}>May</MenuItem>
                                    <MenuItem value={6}>June</MenuItem>
                                    <MenuItem value={7}>July</MenuItem>
                                    <MenuItem value={8}>August</MenuItem>
                                    <MenuItem value={9}>September</MenuItem>
                                    <MenuItem value={10}>October</MenuItem>
                                    <MenuItem value={11}>November</MenuItem>
                                    <MenuItem value={12}>December</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        <Box sx={{width: '100%', height: 250}} >
                            <ResponsiveContainer style={{width: '100%'}} >
                                <BarChart
                                    data={data}
                                    layout="vertical"
                                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                                    >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis type="number" />
                                    <YAxis type="category" dataKey="name" />
                                    <Tooltip />
                                    <Bar dataKey="uv" fill="#8884d8" />
                                </BarChart>
                            </ResponsiveContainer>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={0}>
                <Grid item xs={12} md={6}>
                    <Box component={Paper} textAlign='center' height={450} >
                        <Stack p={1.5} direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{bgcolor: '#F5F5F5'}} >
                            <Typography>Fee not paid</Typography>
                        </Stack>
                        <Box p={1.5}>
                            <TableContainer sx={{maxHeight: 350}} >
                                { notpaid.status === false ? <Alert severity='warning' sx={{mt: 2}} >{notpaid.msg}</Alert> :
                                <Table>
                                    <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell align="left">Name</TableCell>
                                        <TableCell align="left">Roll No.</TableCell>
                                        <TableCell align="right">Join date</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {notpaid.map((row, i) => (
                                        <TableRow
                                        key={i}>
                                        <TableCell align="left">{ (notpaid.length)-i}</TableCell>
                                        <TableCell align="left">{row.name}</TableCell>
                                        <TableCell align="left">{row.rollno}</TableCell>
                                        <TableCell align="right">{new Date(row.joindate).toLocaleDateString(undefined, {month: 'long', year: 'numeric'})}</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table> }
                            </TableContainer>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box component={Paper} textAlign='center' height={450} >
                        <Stack p={1.5} direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{bgcolor: '#F5F5F5'}} >
                            <Typography>New students</Typography>
                            <TextField type='date' size='small' value={filterday} onChange={(event) => setFilterday(event.target.value)} />
                        </Stack>
                        <Box p={1.5}>
                            <TableContainer sx={{maxHeight: 350}} >
                                { student.status === false ? <Alert severity='warning' sx={{mt: 2}} >{student.msg}</Alert> :
                                <Table>
                                    <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell align="left">Name</TableCell>
                                        <TableCell align="left">Guardian</TableCell>
                                        <TableCell align="left">Mobile</TableCell>
                                        <TableCell align="left">Gender</TableCell>
                                        <TableCell align="right">Join date</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {student.map((row, i) => (
                                        <TableRow
                                        key={i}>
                                        <TableCell align="left">{i+1}</TableCell>
                                        <TableCell align="left"><NavLink style={{color: 'darkorange'}} to={`/controller/student-info/${row.studentid}`}>{row.name}</NavLink></TableCell>
                                        <TableCell align="left">{row.guardian}</TableCell>
                                        <TableCell align="left"><NavLink style={{color: 'blue'}} to={`tel: ${row.mobile}`}>{row.mobile}</NavLink></TableCell>
                                        <TableCell align="left">{row.gender}</TableCell>
                                        <TableCell align="right">{row.joindate}</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table> }
                            </TableContainer>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
        </Box>
    );
}