import React, { useEffect, useState } from "react";
import {  } from 'react-router-dom'

// MUI
import { Alert, Box, Button, CircularProgress, Container, Typography, useMediaQuery, useTheme } from '@mui/material'
import { CloudUpload } from '@mui/icons-material'
import { styled } from '@mui/material/styles';
import axios from "axios";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

export default function Gallery({apiadmin, snackbar, gallery}){
    const [action, setAction] = useState('')

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const [galleryimg, setGalleryimg] = useState([])
    useEffect(() => {
        loadgallery()
    }, [])

    const loadgallery = () => {
        const formData = new FormData()
        formData.append('get-gallery', '')
        axios.post(apiadmin, formData)
        .then(function(res){
            res.data.status === false ? setGalleryimg(res.data) : setGalleryimg(res.data.data)
        })
    }

    const uploadtogallery = (event) => {
        var formData = new FormData()
        var gimg = event.target.files;
        for(var i=0; i<gimg.length; i++){
            var images = gimg[i]
            formData.append('add-to-gallery[]', images, images.name)
        }
        axios.post(apiadmin, formData, {
            onUploadProgress: progressEvent => {
                const { loaded, total } = progressEvent;
                setAction(Math.round((loaded * 100) / total))
            }
        })
        .then(function(res){
            setAction('')
            res.data.status === false ? snackbar(res.data.msg) : loadgallery()
        })
    }

    const deleteimg = (img) => {
        snackbar('Deleting...')
        var formData = new FormData()
        formData.append('delete-image-from-gallery', img)
        axios.post(apiadmin, formData)
        .then(function(res){
            snackbar('Deleted')
            res.data.status === true && loadgallery()
        })
    }

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return(
        <Box className='content' >
            <Container>
                <Box textAlign={'center'} >
                    <Button component="label" variant="contained" disabled={action > 0 ? true : false} startIcon={action > 0 ? <CircularProgress color="inherit" size={24} variant="determinate" value={action} /> : <CloudUpload />}>
                        Upload file
                        <VisuallyHiddenInput type="file" accept=".jpg, .jpeg, .png" onChange={(event) => uploadtogallery(event)} multiple />
                    </Button>
                </Box>

                { galleryimg.status === false ? <Alert severity="warning">Please upload images</Alert> :
                <Box>
                    <Typography fontSize={14} color={'gray'} mt={3} >To delete, double click on the image.</Typography>
                    <ImageList cols={isSmallScreen ? 2 : 4} variant="masonry" >
                        {galleryimg.map((item) => (
                            <ImageListItem key={item.img} >
                            <img
                                srcSet={`${gallery+item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                src={`${gallery+item.img}?w=164&h=164&fit=crop&auto=format`}
                                alt={item.title}
                                loading="lazy"
                                onDoubleClick={() => deleteimg(item.img)}
                            />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Box>}

            </Container>            
        </Box>
    );
}