import React, { useEffect, useState } from "react";
import {  } from 'react-router-dom'
import Footer from "./footer";

// MUI
import { Avatar, Box, Container, Divider, Grid, Toolbar, Typography } from '@mui/material'
import {  } from '@mui/icons-material'

export default function VerifiedCertificate({apipublic, snackbar, stuimg}){

    const enrollment = new URLSearchParams(window.location.search).get('enrollment')

    const [info, setInfo] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('verify-certificate', enrollment)
        fetch(apipublic, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.status == false){
                snackbar(data.message)
            } else {
                setInfo(data.data)
            }
        })
    }, [])

    return(
        <Box sx={{background: '#000000', color: 'white'}} >
            <Container>
                <Grid container pb={5} >
                    <Grid item xs={12} md={10} mx={'auto'} mt={5} sx={{border: '2px solid', borderColor: 'secondary.main'}} borderRadius={2} padding={2} >
                        <Box textAlign={'center'} mb={5} >
                            <Avatar sx={{width: 140, height: 140, margin: 'auto'}} src={`${stuimg+info.studentid}.jpg`} />
                            <Typography fontSize={30} fontWeight={'bold'} fontFamily={'fantasy'} mt={3} color={'secondary.main'} letterSpacing={2} >Successfully Verified!</Typography>
                            <Typography mb={2} color={'error.main'} fontSize={15} >Enrollment: {enrollment}</Typography>
                            <Typography fontSize={15} color={'cyan'} mt={4} >Validation achieved! Your certificate now shines as a beacon of your expertise.</Typography>
                            <Typography fontSize={15} color={'cyan'} >Embrace this momentous milestone on your path to success!</Typography>
                            <Typography fontSize={15} color={'warning.main'} mt={5} >Detail of the certificate...</Typography>
                            <Box sx={{background: 'red', width: 100, padding: 0.1, margin: 'auto', mt: 0.2}} />
                        </Box><Divider sx={{bgcolor: 'secondary.main'}} />
                        <Toolbar>
                            <Typography flexGrow={1} >Certificate No</Typography>
                            <Typography fontWeight={'bold'} >{info.certificate_no}</Typography>
                        </Toolbar><Divider sx={{bgcolor: 'secondary.main'}} />
                        <Toolbar>
                            <Typography flexGrow={1} >Name</Typography>
                            <Typography fontWeight={'bold'} >{info.name}</Typography>
                        </Toolbar><Divider sx={{bgcolor: 'secondary.main'}} />
                        <Toolbar>
                            <Typography flexGrow={1} >Father's name</Typography>
                            <Typography fontWeight={'bold'} >{info.guardian}</Typography>
                        </Toolbar><Divider sx={{bgcolor: 'secondary.main'}} />
                        <Toolbar>
                            <Typography flexGrow={1} >Mother's name</Typography>
                            <Typography fontWeight={'bold'} >{info.mother}</Typography>
                        </Toolbar><Divider sx={{bgcolor: 'secondary.main'}} />
                        <Toolbar>
                            <Typography flexGrow={1} >Birth date</Typography>
                            <Typography fontWeight={'bold'} >{info.birth}</Typography>
                        </Toolbar><Divider sx={{bgcolor: 'secondary.main'}} />
                        <Toolbar>
                            <Typography flexGrow={1} >Joined course</Typography>
                            <Typography fontWeight={'bold'} >{info.c_title}/ {info.c_name}</Typography>
                        </Toolbar><Divider sx={{bgcolor: 'secondary.main'}} />
                        <Toolbar>
                            <Typography flexGrow={1} >Issue date</Typography>
                            <Typography fontWeight={'bold'} >{info.issued_on}</Typography>
                        </Toolbar><Divider sx={{bgcolor: 'secondary.main'}} />
                        <Toolbar>
                            <Typography flexGrow={1} >Study center</Typography>
                            <Typography fontWeight={'bold'} >{info.fr_name}</Typography>
                        </Toolbar>
                    </Grid>
                </Grid>
            </Container><Footer/>
        </Box>
    );
}