import React, { useRef, useState } from "react";
import {  } from 'react-router-dom'

// MUI
import { Box, Button, Container } from '@mui/material'
import { CloudUpload, Download } from '@mui/icons-material'
import styled from "@emotion/styled";
import html2canvas from "html2canvas";

export default function SharePublic(){

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });
    
    const [image, setImage] = useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(file);
        }
    };

    const divRef = useRef(null);

    const handleDownload = () => {
        // Use html2canvas to capture the div content as an image
        html2canvas(divRef.current).then(canvas => {
        // Convert canvas to image data
        const imageData = canvas.toDataURL('image/jpg');

        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = imageData;
        link.download = 'holi.jpg';
        document.body.appendChild(link);
        
        // Trigger the download
        link.click();

        // Clean up
        document.body.removeChild(link);
        });
    };


    return(
        <Box>
            <Container sx={{pt: 5}} >
                <Box textAlign={'center'} mb={4} >
                    <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                        Select your image
                        <VisuallyHiddenInput type="file" accept=".jpeg, .jpg, .png" onChange={(event) => handleImageChange(event)} />
                    </Button>
                    <Button variant="contained" color="warning" sx={{ml: 1}} onClick={() => handleDownload()} ><Download/></Button>
                </Box>
                <Box overflow={'auto'}>
                    <Box ref={divRef} sx={{position: 'relative', width: 1000, height: 1000}} >
                        <img src="/images/holi.jpg" alt="Holi" style={{width: 1000, height: 1000}} />
                        <Box sx={{position: 'absolute', bottom: 10, left: 13, width: 457, height: 693,
                        backgroundImage: `url(${image})` ,
                        backgroundSize: 'cover'
                        }} >

                        </Box>
                    </Box>
                </Box>
            </Container>            
        </Box>
    );
}