import React from "react";
import { useNavigate } from 'react-router-dom'

// MUI
import { Box, Button, Stack, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'

export default function Back({text}){
    let navi = useNavigate()
    return(
        <Stack direction='row' justifyContent='space-between' alignItems='center' mb={2} sx={{borderBottom: 'thin solid #BEBFC5'}} >
            <Box flexGrow={1} >
                <Button onClick={() => navi(-1)} startIcon={<ArrowBack/>} >Back</Button>
            </Box>
            <Typography noWrap fontSize={14} fontWeight='bold' >{text}</Typography>
        </Stack>
    );
}