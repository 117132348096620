import React from "react";
import {  } from 'react-router-dom'

// MUI
import { Box, Button, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { Edit } from '@mui/icons-material'

export default function Batch(){



    return(
        <Box className='content' >
            <Container>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell align="left">Start</TableCell>
                                <TableCell align="left">End</TableCell>
                                <TableCell align="right">Status</TableCell>
                                <TableCell align="right">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>1</TableCell>
                                <TableCell align="left">
                                    <Typography fontWeight={'bold'} >10 Am</Typography>
                                </TableCell>
                                <TableCell align="left">
                                    <Typography fontWeight={'bold'} >11 Am</Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Chip variant="contained" label='Active' color="success" />
                                </TableCell>
                                <TableCell align="right">
                                    <Chip variant="contained" label='Edit' color="warning" icon={<Edit />} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>

            <Dialog open={false} fullWidth maxWidth='xs'>
                <DialogTitle>Batch detail</DialogTitle><Divider/>
                <DialogContent>
                    <Stack direction={'column'} spacing={1.5}>
                        <TextField variant="outlined" helperText='Start time' type="time" />
                        <TextField variant="outlined" helperText='End time' type="time" />
                        <FormControl fullWidth>
                            <InputLabel>Status</InputLabel>
                            <Select
                                value={''}
                                label="Status"
                                onChange=''
                            >
                                <MenuItem value={''}>Active</MenuItem>
                                <MenuItem value={''}>Closed</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                </DialogContent><Divider/>
                <DialogActions>
                    <Button variant="outlined" color="inherit" >Close</Button>
                    <Button variant="contained" color="info" >Upload</Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
}