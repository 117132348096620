import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from 'react-router-dom'

// MUI
import { Alert, Box, Button, Card, CardContent, Container, Divider, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, LinearProgress, Stack, Typography } from '@mui/material'
import { AccountTree, Add, Edit, Search } from '@mui/icons-material'

export default function Students({auth, apiglobal}){
    let navi = useNavigate()
    
    var url = new URLSearchParams(window.location.search).get('value')
    const [searchquery, setSearchquery] = useState(url?url:'')

    useEffect(() => {
        getStudents()
    }, [])
    const [students, setStudents] = useState([])
    const [loading, setLoading] = useState(true)
    const getStudents = () => {
        var formData = new FormData()
        formData.append('get-students', searchquery)
        fetch(apiglobal, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.status === false){
                setStudents(data)
            } else {
                setStudents(data.data)
            }
        })
    }

    const search_student = () => {
        navi(`?value=${searchquery}`, {replace: true})
        getStudents()
    }

    return(
        <Box className='content' >
            <Container>
                <FormControl variant="standard" fullWidth>
                    <InputLabel>Search student by name, mobile</InputLabel>
                    <Input
                        value={searchquery}
                        onChange={(event) => setSearchquery(event.target.value)}
                        type='text'
                        onKeyUp={(event) => searchquery.length > 0 && event.key === 'Enter' && search_student()}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton disabled={searchquery.length > 0 ? false : true} onClick={() => search_student()} ><Search/></IconButton>
                        </InputAdornment>
                        }
                    />
                </FormControl>

                <Stack direction='row' alignItems='center' justifyContent='space-between' mb={2} mt={1} >
                    <Typography fontSize={14} fontWeight='bold' >Students</Typography>
                    <Box>
                        <Button startIcon={<Add/>} variant="outlined" onClick={() => navi('/controller/student-upload/0')} >Add Student</Button>
                    </Box>
                </Stack>
                
                <Grid container>
                    <Grid item xs={12} md={7} mx='auto' >
                        { loading ? <LinearProgress color="primary" /> : students.status === false ? <Alert severity="warning">{students.message}</Alert> :
                        students.map((data, i) =>
                        <Card key={i} sx={{mb: 1}} >
                            <CardContent>
                                <Stack direction='row' alignItems='center' justifyContent='space-between' >
                                    <Box>
                                        <Typography fontSize={20} fontWeight='bold' noWrap >{data.name}</Typography>
                                        <Typography fontSize={14} color='gray' >{data.joindate}</Typography>
                                    </Box>
                                    <Stack direction='row' >
                                        <IconButton sx={{background: '#F5F5F5'}} onClick={() => navi(`/controller/student-upload/${data.studentid}`)} ><Edit/></IconButton>
                                        <IconButton sx={{background: '#F5F5F5', ml: 0.5}} onClick={() => navi(`/controller/student-info/${data.studentid}`)} ><AccountTree/></IconButton>
                                    </Stack>
                                </Stack>
                                <Box mt={1} mb={1} >
                                    <Typography fontSize={14} color='gray' >Email - {data.email}</Typography>
                                    <Typography fontSize={14} color='gray' >Mobile - <NavLink to={`tel: ${data.mobile}`}>{data.mobile}</NavLink></Typography>
                                    {auth === 'admin' && <Typography fontSize={14} color='gray' >Institute - {data.fr_name}</Typography> }
                                </Box><Divider/>
                                <Stack direction='row' alignItems='center' justifyContent='space-between' mt={1} >
                                    <Typography fontSize={14} color='gray' >Status</Typography>
                                    <Typography fontSize={14} color={data.status === 'active' ? 'green' : data.status === 'pending' ? 'darkorange' : 'darkblue'} fontWeight='bold' textTransform='capitalize' >{data.status}</Typography>
                                </Stack>
                            </CardContent>
                        </Card>) }
                    </Grid>
                </Grid>
            </Container>            
        </Box>
    );
}