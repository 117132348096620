import React from "react";
import {  } from 'react-router-dom'

// MUI
import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import { Call, LocationOn, Mail, WhatsApp } from '@mui/icons-material'

export default function Contact(){

    return(
        <Box sx={{background: '#000000', color: 'secondary.main'}} pb={3} pt={{xs: 4, md: 0}} >
            <Container sx={{height: {xs: 'auto', md: '90vh'}, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} >
                <Stack direction={{xs: 'column', md: 'row'}} sx={{width: '100%'}} spacing={2} >
                    <Box borderRadius={2} overflow={'hidden'} sx={{border: 'thin solid', borderColor: 'secondary.main', width: {md: '40%'}}} padding={2} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} >
                        <img src="/images/img3.png" alt="Spyware Comptuter Education" style={{width: 370}} />
                    </Box>
                    <Box borderRadius={2} overflow={'hidden'} sx={{border: 'thin solid', borderColor: 'secondary.main', width: {md: '60%'}}} padding={2} display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} >
                        <Box sx={{width: '100%'}} >
                            <Typography color={'error'} fontSize={{xs: 30, md: 35}} fontWeight={'bold'} className="anno" >We can assist you by...</Typography>

                            <Grid container spacing={1} >
                                <Grid item xs={6} md={4}>
                                    <Box textAlign={'center'} sx={{cursor: 'pointer', border: 'thin solid', color: 'secondary.main'}} padding={2.5} borderRadius={2} onClick={() => window.location.href = 'tel: 7808426759'} >
                                        <Box><Call color="secondary" fontSize="large" /></Box>
                                        <Typography>Phone Call</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <Box textAlign={'center'} sx={{cursor: 'pointer', border: 'thin solid', color: 'secondary.main'}} padding={2.5} borderRadius={2} onClick={() => window.location.href = 'https://wa.me/917808426759'} >
                                        <Box><WhatsApp color="secondary" fontSize="large" /></Box>
                                        <Typography>WhatsApp Chat</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box textAlign={'center'} sx={{cursor: 'pointer', border: 'thin solid', color: 'secondary.main'}} padding={2.5} borderRadius={2} >
                                        <Box><Mail color="secondary" fontSize="large" /></Box>
                                        <Typography>E-Mail</Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Typography mt={4} color={'error'} fontSize={{xs: 30, md: 35}} fontWeight={'bold'} className="anno" >You can reach to us...</Typography>
                            <Box textAlign={'center'} sx={{cursor: 'pointer', border: 'thin solid', color: 'secondary.main'}} padding={2} borderRadius={2} >
                                <Box><LocationOn color="secondary" fontSize="large" /></Box>
                                <Typography>Address</Typography>
                                <Typography>Mirchai bari road, Katihar - 854105, Bihar</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Stack>  
            </Container>
        </Box>
    );
}