import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom'
import Back from "../bar";

// MUI
import { Box, Button, CircularProgress, Container, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material'
import {  } from '@mui/icons-material'

export default function CourseUpload({apiadmin, snackbar}){
    let navi = useNavigate()
    const { courseid } = useParams()

    const [title, setTitle] = useState('')
    const [name, setName] = useState('')
    const [duration, setDuration] = useState('')
    const [status, setStatus] = useState('active')

    useEffect(() => {
        var formData = new FormData()
        formData.append('get-course-details', courseid)
        fetch(apiadmin, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.status == true){
                setTitle(data.data.c_title); setName(data.data.c_name); setDuration(data.data.c_duration); setStatus(data.data.c_status)
            }
        })
    }, [courseid])

    const [uploading, setUploading] = useState(false)
    const upload = () => {
        setUploading(true)
        var formData = new FormData()
        formData.append('add-course', courseid)
        formData.append('title', title); formData.append('name', name); formData.append('duration', duration); formData.append('status', status)
        fetch(apiadmin, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setUploading(false)
            if(data.status == false){
                snackbar(data.message)
            } else {
                navi(-1)
            }
        })
    }

    return(
        <Box className='content' >
            <Container>
                <Back text='Franchise upload'/>

                <Stack direction={{xs: 'column', md: 'row'}} spacing={2} >
                    <TextField fullWidth variant="outlined" label='Title*' type="text" value={title} onChange={(event) => setTitle(event.target.value)} />
                    <TextField fullWidth variant="outlined" label='Name*' type="text" value={name} onChange={(event) => setName(event.target.value)} />
                </Stack>
                <Stack direction={{xs: 'column', md: 'row'}} spacing={2} mt={2} >
                    <TextField fullWidth variant="outlined" label='duration(in months)' type="number" value={duration} onChange={(event) => setDuration(event.target.value)} />
                    <FormControl fullWidth >
                        <InputLabel>Status*</InputLabel>
                        <Select label='Status' value={status} onChange={(event) => setStatus(event.target.value)} >
                            <MenuItem value='active' >Active</MenuItem>
                            <MenuItem value='archive' >Archive</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <Stack textAlign='right' spacing={1} direction='row' justifyContent='end' mt={2} >
                    <Button variant='outlined' onClick={() => navi(-1)} >Back</Button>
                    <Button variant="contained" sx={{width: '100%'}} onClick={() => upload()} disabled={uploading} >{uploading ? <CircularProgress color="inherit" size={24} /> : 'Upload'}</Button>
                </Stack>
            </Container>
        </Box>
    );
}