import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom'
import QRCode from "react-qr-code";

// MUI
import { AppBar, Avatar, Box, Container, IconButton, Table, TableBody, TableCell, TableRow, Toolbar, Typography } from '@mui/material'
import { ArrowBackIosNew, Download } from '@mui/icons-material'
import html2canvas from "html2canvas";
import axios from "axios";
import MyContext from "../../context/context";

export default function IDCard(){

    const { studentid } = useParams()
    const { courseid } = useParams()
    const navi = useNavigate()

    const api = useContext(MyContext)

    const divRef = useRef(null);

    const handleDownload = () => {
        // Use html2canvas to capture the div content as an image
        html2canvas(divRef.current).then(canvas => {
        // Convert canvas to image data
        const imageData = canvas.toDataURL('image/jpg');

        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = imageData;
        link.download = idcard.name+'_idcard.jpg';
        document.body.appendChild(link);
        
        // Trigger the download
        link.click();

        // Clean up
        document.body.removeChild(link);
        });
    };

    const [idcard, setIdcard] = useState([])
    useEffect(() => {
        axios.get(`${api.api.global}?id-card-detail=${studentid}&courseid=${courseid}`)
        .then(function(res){
            if(res.data.status === false){
                setIdcard(res.data.data)
            }
        })
    }, [])

    return(
        <Box>
            <AppBar position="sticky" elevation={0} >
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => navi(-1)}
                    >
                        <ArrowBackIosNew />
                    </IconButton>
                    <Typography sx={{ flexGrow: 1 }}>ID card</Typography>

                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => handleDownload()}
                    >
                        <Download />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Container>
                <Box sx={{overflow: 'auto'}} mt={2} >
                    <Box ref={divRef} sx={{position: 'relative', width: '108mm', height: '172mm'}} mx={'auto'} >
                        <Box textAlign={'center'} sx={{background: '#007FFF'}} pb={6} >
                            <Typography fontSize={40} letterSpacing={15} fontWeight={600} className="orbitron" color={'#FFFFFF'} >SPYWARE</Typography>
                            <Typography className="orbitron" fontSize={14} letterSpacing={3} color={'#FFFFFF'} >COMPUTER EDUCATION</Typography>
                        </Box>
                        <Avatar src={`${api.api.stuimg+studentid}.jpg`} sx={{borderRadius: 2, margin: 'auto', width: 140, height: 140, marginTop: -5}} />

                        <Box mt={2} >
                            <Typography textAlign={'center'} fontSize={30} className="cert-font" fontWeight={600} >{idcard.name}</Typography>
                        </Box>
                        <Box mt={1} pl={1} pr={1} >
                            <Table size="small" sx={{ border: 'none', borderCollapse: 'collapse' }} >
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <Typography fontSize={10} color={'gray'} >Join date</Typography>
                                            <Typography fontSize={15} fontWeight={600} >{new Date(idcard.joindate).toLocaleDateString(undefined, {day: '2-digit', month: 'long', year: '2-digit'})}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography fontSize={10} color={'gray'} >Roll No.</Typography>
                                            <Typography fontSize={15} fontWeight={600} >{idcard.rollno}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography fontSize={10} color={'gray'} >Course</Typography>
                                            <Typography fontSize={15} fontWeight={800} color={'#FF4F00'} >{idcard.c_title} &#10004;</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography fontSize={10} color={'gray'} >Birth date</Typography>
                                            <Typography fontSize={15} fontWeight={600} >{new Date(idcard.birth).toLocaleDateString(undefined, {day: '2-digit', month: 'long', year: 'numeric'})}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>

                        <Box sx={{ position: 'absolute', bottom: '8mm', width: '108mm', textAlign: 'center', margin: 'auto'}} >
                            <QRCode size={200} value={studentid} viewBox={`0 0 256 256`} />
                        </Box>
                        <Box p={0.4} sx={{position: 'absolute', bottom: '0', width: '108mm', background: '#007FFF'}} textAlign={'center'} >
                            <Typography color={'#FFFFFF'} fontSize={14} fontWeight={500} >www.spywarecomputers.com</Typography>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}