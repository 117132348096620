import React, { useContext, useEffect, useRef, useState } from "react";
import {  } from 'react-router-dom'

// MUI
import { Box, Button, Card, CardContent, Container, Divider, Stack, Typography } from '@mui/material'
import { Download } from '@mui/icons-material'
import Header from "./header";
import MyContext from "../context/context";
import axios from "axios";
import html2canvas from "html2canvas";

export default function PaymentReceipt(){
    const api = useContext(MyContext)

    const paymentid = new URLSearchParams(window.location.search).get('pid')
    const studentid = new URLSearchParams(window.location.search).get('sid')

    const [payment, setPayment] = useState([])

    useEffect(() => {
        axios.get(`${api.api.public}?payment-receipt=${paymentid}&studentid=${studentid}`)
        .then(function(res){
            res.data.status === true ? setPayment(res.data.data) : setPayment([])
        })
    }, [])

    const divRef = useRef(null);

    const handleDownload = () => {
        // Use html2canvas to capture the div content as an image
        html2canvas(divRef.current).then(canvas => {
        // Convert canvas to image data
        const imageData = canvas.toDataURL('image/jpg');

        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = imageData;
        link.download = payment.name + 'payment_receipt.jpg' ;
        document.body.appendChild(link);
        
        // Trigger the download
        link.click();

        // Clean up
        document.body.removeChild(link);
        });
    };

    return(
        <Box><Header />
            <Container sx={{mt: 3}} >
                
                <Box ref={divRef} width={{xs: '100%', md: '500px'}} margin={'auto'} >



                    <Typography mb={1} fontSize={18} fontWeight={600} >Payment receipt</Typography>
                    <Card>
                        <CardContent>
                            <Stack mb={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography fontSize={12} color={'gray'} >Institute name</Typography>
                                <Typography fontSize={12} fontWeight={600} >{payment.fr_name}</Typography>
                            </Stack>
                            <Stack mb={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography fontSize={12} color={'gray'} >Code</Typography>
                                <Typography fontSize={12} fontWeight={600} >{payment.fr_code}</Typography>
                            </Stack>
                            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography fontSize={12} color={'gray'} >Address</Typography>
                                <Typography fontSize={12} fontWeight={600} >{payment.fr_address}, {payment.fr_city}</Typography>
                            </Stack>
                        </CardContent>
                    </Card>

                    <Typography mt={4} mb={1} fontSize={18} fontWeight={600} >Payment details</Typography>
                    <Card>
                        <CardContent>
                            <Stack mb={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography fontSize={12} color={'gray'} >Student name</Typography>
                                <Typography fontSize={12} fontWeight={600} >{payment.name}</Typography>
                            </Stack>
                            <Stack mb={1.5} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography fontSize={12} color={'gray'} >Roll no.</Typography>
                                <Typography fontSize={12} fontWeight={600} >{payment.rollno}</Typography>
                            </Stack>
                            <Divider/>
                            <Stack mt={1.5} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography fontSize={12} color={'gray'} >Payment ID</Typography>
                                <Typography fontSize={12} fontWeight={600} >{paymentid}</Typography>
                            </Stack>
                            <Stack mt={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography fontSize={12} color={'gray'} >Amount</Typography>
                                <Typography fontSize={12} fontWeight={600} >{parseInt(payment.amount).toLocaleString('en-in')} &#8377;</Typography>
                            </Stack>
                            <Stack mt={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography fontSize={12} color={'gray'} >Payment date</Typography>
                                <Typography fontSize={12} fontWeight={600} >{new Date(payment.p_date).toLocaleDateString(undefined, {day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true})}</Typography>
                            </Stack>
                        </CardContent>
                    </Card>
                </Box>
                <Box pt={4} width={{xs: '100%', md: '500px'}} margin={'auto'} >
                    <Button size="large" onClick={() => handleDownload()} variant="contained" sx={{width: '100%', color: 'white'}} startIcon={<Download/>} >Download receipt</Button>
                </Box>
            </Container>
        </Box>
    );
}