import React, { useContext, useEffect, useState } from "react";
import {  } from 'react-router-dom'

import MyContext from "../context/context";

// MUI
import { Box, Card, CardContent, Chip, Container, Divider, Stack, Typography } from '@mui/material'
import { AccessTime, CalendarMonth, KeyboardDoubleArrowDown, KeyboardDoubleArrowUp } from '@mui/icons-material'
import axios from "axios";

export default function AttendanceStudent(){
    const api = useContext(MyContext)
    useEffect(() => {
        window.scroll(0,0)
    }, [])

    const [att, setAtt] = useState([])
    const [his, setHis] = useState([])
    useEffect(() => {
        axios.get(`${api.api.student}?attendance-history`)
        .then(function(res){
            res.data.att.status === true && setAtt(res.data.att.data)
            res.data.his.status === true && setHis(res.data.his.data)
        })
    }, [])


    return(
        <Box className='content' >
            <Container>
                <Card>
                    <CardContent>
                        <Typography mb={2} fontSize={20} fontWeight={600} color={`info.main`} >Attendance</Typography>
                        <Stack mb={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography fontSize={14} color={'gray'} >Attend days</Typography>
                            <Chip icon={<CalendarMonth />} label={`${att.attend} Days came`} variant="outlined" color="error" />
                        </Stack><Divider/>
                        <Stack mt={1} mb={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontSize={14} color={'gray'} >{new Date().toLocaleDateString(undefined, {weekday: 'long', day: '2-digit'})} In</Typography>
                            <Chip icon={<AccessTime />} label={`${att.at_in !== null ? new Date(att.at_in).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', hour12: true }) : 'Waiting' } `} color="info" />
                        </Stack><Divider/>
                        <Stack mt={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontSize={14} color={'gray'} >{new Date().toLocaleDateString(undefined, {weekday: 'long', day: '2-digit'})} Out</Typography>
                            <Chip icon={<AccessTime />} label={`${att.at_out !== null ? new Date(att.at_out).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', hour12: true }) : 'Waiting' } `} color="warning" />
                        </Stack>
                    </CardContent>
                </Card>

                <Typography mt={3} mb={1} fontSize={20} fontWeight={600} color={`info.main`} >Previous attend</Typography>
                { his.map((row, i) =>
                <Card sx={{mt: 2}} key={i} >
                    <CardContent>
                        <Chip icon={<AccessTime />} label={`${new Date(row.at_in).toLocaleDateString(undefined, { day: '2-digit', month: 'long', year: 'numeric' })} `} color="info" />
                        <Stack mt={1} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography color={'gray'} fontSize={14} >Attend in</Typography>
                            <Chip variant="outlined" icon={<KeyboardDoubleArrowDown />} label={`${row.at_in !== null ? new Date(row.at_in).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', hour12: true }) : 'Waiting' } `} color="warning" />
                        </Stack>
                        <Stack mt={1} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography color={'gray'} fontSize={14} >Attend out</Typography>
                            <Chip variant="outlined" icon={<KeyboardDoubleArrowUp />} label={`${row.at_out !== null ? new Date(row.at_out).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', hour12: true }) : 'Waiting' } `} color="warning" />
                        </Stack>
                    </CardContent>
                </Card> ) }
            </Container>
        </Box>
    );
}