import React, { useContext, useEffect, useState } from "react";
import {  } from 'react-router-dom'

// MUI
import { Box, Card, CardContent, Chip, Container, FormControl, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { AccountBalanceWallet, CurrencyRupee, LocalOffer } from '@mui/icons-material'
import MyContext from "../../context/context";
import axios from "axios";
import Back from "../../bar";

export default function PaymentReport(){
    const api = useContext(MyContext)

    const date = new Date()
    const getmonth = date.getMonth() + 1
    const getdate = date.getDate()

    const [month, setMonth] = useState(getmonth)
    const [day, setDay] = useState(getdate)

    const [report, setReport] = useState([])
    useEffect(() => {
        axios.get(`${api.api.global}?daily-payment-report=${month}&day=${day}`)
        .then(function(res){
            res.data.status === true ? setReport(res.data.data) : setReport(res.data)
        })
    }, [month, day])

    return(
        <Box className='content' >
            <Container>
                <Back text='Payment report' />
                <Card sx={{mt: 2}} >
                    <CardContent>
                        <Stack spacing={2} direction={{xs: 'column', md: 'row'}} justifyContent={'space-between'} alignItems={'center'} >
                            <Box>
                                <Chip color="info" label={`Total count: ${report.status === false ? 0 : report.length } `} icon={<LocalOffer />} />
                                <Chip color="success" label={`Total credit: ${report.status === false ? 0 : report.reduce((t, i) => t + parseInt(i.amount), 0) } `} sx={{ml: 1}} icon={<AccountBalanceWallet />} />
                            </Box>
                            <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                                <FormControl size="small" >
                                    <InputLabel id="demo-simple-select-label">Month</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={month}
                                        label="Month"
                                        onChange={(event) => setMonth(event.target.value)}
                                    >
                                        <MenuItem value={1}>January</MenuItem>
                                        <MenuItem value={2}>February</MenuItem>
                                        <MenuItem value={3}>March</MenuItem>
                                        <MenuItem value={4}>April</MenuItem>
                                        <MenuItem value={5}>May</MenuItem>
                                        <MenuItem value={6}>June</MenuItem>
                                        <MenuItem value={7}>July</MenuItem>
                                        <MenuItem value={8}>August</MenuItem>
                                        <MenuItem value={9}>September</MenuItem>
                                        <MenuItem value={10}>October</MenuItem>
                                        <MenuItem value={11}>November</MenuItem>
                                        <MenuItem value={12}>December</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl size="small" sx={{ml: 0.5}}>
                                    <InputLabel id="demo-simple-select-label">Day</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={day}
                                        label="Day"
                                        onChange={(event) => setDay(event.target.value)}
                                    >
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                        <MenuItem value={4}>4</MenuItem>
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={6}>6</MenuItem>
                                        <MenuItem value={7}>7</MenuItem>
                                        <MenuItem value={8}>8</MenuItem>
                                        <MenuItem value={9}>9</MenuItem>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={11}>11</MenuItem>
                                        <MenuItem value={12}>12</MenuItem>
                                        <MenuItem value={13}>13</MenuItem>
                                        <MenuItem value={14}>14</MenuItem>
                                        <MenuItem value={15}>15</MenuItem>
                                        <MenuItem value={16}>16</MenuItem>
                                        <MenuItem value={17}>17</MenuItem>
                                        <MenuItem value={18}>18</MenuItem>
                                        <MenuItem value={19}>19</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                        <MenuItem value={21}>21</MenuItem>
                                        <MenuItem value={22}>22</MenuItem>
                                        <MenuItem value={23}>23</MenuItem>
                                        <MenuItem value={24}>24</MenuItem>
                                        <MenuItem value={25}>25</MenuItem>
                                        <MenuItem value={26}>26</MenuItem>
                                        <MenuItem value={27}>27</MenuItem>
                                        <MenuItem value={28}>28</MenuItem>
                                        <MenuItem value={29}>29</MenuItem>
                                        <MenuItem value={30}>30</MenuItem>
                                        <MenuItem value={31}>31</MenuItem>
                                    </Select>
                                </FormControl>
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
                <TableContainer sx={{mt: 2}} component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" >#</TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Roll no</TableCell>
                                <TableCell align="left">Date</TableCell>
                                <TableCell align="right">Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { report.status !== false && report.map((row, i) =>
                            <TableRow key={i} >
                                <TableCell component="th" scope="row" align="left" >{i+1}</TableCell>
                                <TableCell align="left">{row.name}</TableCell>
                                <TableCell align="left">{row.rollno}</TableCell>
                                <TableCell align="left"><Chip variant="outlined" color="info" label={new Date(row.p_date).toLocaleDateString(undefined, {year: 'numeric', month: 'numeric', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true})} /> </TableCell>
                                <TableCell align="right"><Chip variant="contained" color="success" label={parseInt(row.amount).toLocaleString('en-in')} icon={<CurrencyRupee/>} /> </TableCell>
                            </TableRow> ) }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </Box>
    );
}