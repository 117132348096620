import React, { useEffect, useState } from "react";
import {  } from 'react-router-dom'

// MUI
import { Box, Button, Container, Typography } from '@mui/material'
import { GetApp } from '@mui/icons-material'

export default function InstallApp(){

    const [deferredPrompt, setDeferredPrompt] = useState(null);

    useEffect(() => {
        const handleBeforeInstallPrompt = (event) => {
        event.preventDefault();
        setDeferredPrompt(event);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
        window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleInstallClick = () => {
        if (deferredPrompt) {
        deferredPrompt.prompt();

        deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the install prompt');
            } else {
            console.log('User dismissed the install prompt');
            }

            setDeferredPrompt(null);
        });
        }
    };

    return(
        <Box sx={{background: '#000000', height: '100vh', color: 'white'}} >
            <Container>
                <Box textAlign={'center'} pt={10} >
                    <GetApp sx={{fontSize: 200}} />
                    <Typography fontSize={30} fontWeight={'bold'} >SPYWARE COMPUTER EDUCATION</Typography>
                    <Typography mt={2} >Light App</Typography>

                    <Box mt={3} >
                        <Button variant="contained" size="large" color="secondary" onClick={() => handleInstallClick()} >Download Light App</Button>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}