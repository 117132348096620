import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom'
import MyContext from "../../context/context";
import QRCode from "react-qr-code";

// MUI
import { Box, Button, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, Input, InputAdornment, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { Edit, Print, Search } from '@mui/icons-material'
import axios from "axios";
import { useReactToPrint } from "react-to-print";

export default function WorkshopData({snackbar}){
    const navi = useNavigate()
    const api = useContext(MyContext)
    const [action, setAction] = useState('')
    const [open, setOpen] = useState('')

    var url = new URLSearchParams(window.location.search).get('value')
    const [searchquery, setSearchquery] = useState(url?url:'')

    const search_student = () => {
        navi(`?value=${searchquery}`, {replace: true})
        getStudents()
    }

    useEffect(() => {
        getStudents()
    }, [])
    const [wdata, setWdata] = useState([])
    const getStudents = () => {
        axios.get(`${api.api.admin}?workshop-data=${searchquery}`)
        .then(function(res){
            res.data.status === true ? setWdata(res.data.data) : setWdata(res.data)
        })
    }

    const area = useRef()
    const print = useReactToPrint({
        content:() => area.current
    })

    const [name, setName] = useState('')
    const [gen, setGen] = useState('')
    const [birth, setBirth] = useState('')
    const [meal, setMeal] = useState('')
    const [email, setEmail] = useState('')
    const [aadhaar, setAadhaar] = useState('')
    const [mobile, setMobile] = useState('')
    const [formstatus, setFormstatus] = useState('')
    const [seat, setSeat] = useState('')

    const open_print = (name, gen, birth, meal, seat) => {
        setName(name); setGen(gen); setBirth(birth); setMeal(meal); setSeat(seat)
        setOpen('print')
    }

    const open_form = (mobile) => {
        get_form_detail(mobile)
        setMobile(mobile); setOpen('form')
    }

    const get_form_detail = (mobile) => {
        axios.get(`${api.api.admin}?workshop-form-details=${mobile}`)
        .then(function(res){
            if(res.data.status === true){
                setMobile(res.data.data.mobile)
                setName(res.data.data.name)
                setGen(res.data.data.gender)
                setBirth(res.data.data.birth)
                setMeal(res.data.data.meal)
                setEmail(res.data.data.email)
                setAadhaar(res.data.data.aadhaar)
                setFormstatus(res.data.data.status)
            }
        })
    }

    const update_form = () => {
        setAction('form-updating')
        var formData = new FormData()
        formData.append('update-workshop-form', mobile)
        formData.append('name', name)
        formData.append('gender', gen)
        formData.append('birth', birth)
        formData.append('meal', meal)
        formData.append('email', email)
        formData.append('aadhaar', aadhaar)
        formData.append('status', formstatus)
        axios.post(api.api.admin, formData)
        .then(function(res){
            setAction('')
            snackbar(res.data.msg)
            if(res.data.status === true){
                getStudents()
                setOpen('')
            }
        })
    }

    const area2 = useRef()
    const print_cert = useReactToPrint({
        content:() => area2.current
    })
    const area3 = useRef()
    const print_scho = useReactToPrint({
        content:() => area3.current
    })
    const [cert, setCert] = useState([])
    const open_cert = (mobile) => {
        setOpen('opencert')
        axios.get(`${api.api.admin}?workshop-form-details=${mobile}`)
        .then(function(res){
            if(res.data.status === true){
                setCert(res.data.data)
            }
        })
    }

    return(
        <Box className='content' >
            <Dialog open={open === 'print' ? true : false} fullWidth maxWidth='md' onClose={() => setOpen('')} >
                <DialogTitle>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography fontWeight={500} color={'info.main'}>Workshop ticket</Typography>
                        <IconButton edge='eng' onClick={() => print()} ><Print/></IconButton>
                    </Stack>
                </DialogTitle><Divider/>
                <DialogContent>
                    <Box sx={{position: 'relative', width: '210mm'}} ref={area} >
                        <img src="/images/ticket.jpg" style={{width: '100%'}} />
                        <Typography sx={{position: 'absolute', top: 20, left: 350}} color={'red'} fontSize={18} fontWeight={600} className="orbitron" >Seat No. {seat}</Typography>
                        <Box sx={{position: 'absolute', left: 10, top: 110, width: 190}} >
                            <Typography fontSize={12} color={'gray'} >Name: {name}</Typography>
                            <Typography fontSize={12} color={'gray'} >Gender: {gen}</Typography>
                            <Typography fontSize={12} color={'gray'} >Birth date: {new Date(birth).toLocaleDateString(undefined, {day: '2-digit', month: 'long', year: 'numeric'})}</Typography>
                            <Typography fontSize={12} color={'gray'} >Meal: {meal}</Typography>
                        </Box>
                        <Box sx={{ position: 'absolute', top: '3mm', right: '3mm', background: 'white', padding: '0.4em 0.4em 0 0.4em'}} >
                            <QRCode size={80} value={`Name: ${name} / Birth: ${birth} / Meal: ${meal} / Seat No. 000${seat}`} viewBox={`0 0 256 256`} />
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            <Dialog open={open === 'form' ? true : false} fullWidth maxWidth='md' onClose={()=> setOpen('')} >
                <DialogTitle>Form details</DialogTitle><Divider/>
                <DialogContent>
                    <Stack spacing={1} direction={{xs: 'column', md: 'row'}} >
                        <TextField fullWidth variant="outlined" label='Name' type="text" value={name} onChange={(event) => setName(event.target.value)} />
                        <TextField fullWidth variant="outlined" label='Email' type="email" value={email} onChange={(event) => setEmail(event.target.value)} />
                        <TextField fullWidth variant="outlined" label='Aadhaar no.' type="number" value={aadhaar} onChange={(event) => setAadhaar(event.target.value)} />
                    </Stack>
                    <Stack spacing={1} mt={1} direction={{xs: 'column', md: 'row'}}>
                        <FormControl fullWidth>
                            <InputLabel>Gender</InputLabel>
                            <Select
                                value={gen}
                                label="Gender"
                                onChange={(event) => setGen(event.target.value)}
                            >
                                <MenuItem value={'Male'}>Male</MenuItem>
                                <MenuItem value={'Female'}>Female</MenuItem>
                                <MenuItem value={'Others'}>Others</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel>Meal</InputLabel>
                            <Select
                                value={meal}
                                label="Meal"
                                onChange={(event) => setMeal(event.target.value)}
                            >
                                <MenuItem value={'Veg'}>Veg.</MenuItem>
                                <MenuItem value={'Non Veg.'}>Non Veg.</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                    <Stack spacing={1} mt={1} direction={{xs: 'column', md: 'row'}} >
                        <TextField fullWidth variant="outlined" disabled label='Mobile number' type="number" value={mobile} onChange={(event) => setMobile(event.target.value)} />
                        <FormControl fullWidth>
                            <InputLabel>Status</InputLabel>
                            <Select
                                value={formstatus}
                                label="Status"
                                onChange={(event)=> setFormstatus(event.target.value)}
                            >
                                <MenuItem value={'pending'}>Pending</MenuItem>
                                <MenuItem value={'confirmed'}>Confirmed</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                </DialogContent><Divider/>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setOpen('')} >Close</Button>
                    <Button variant="contained" color="success" onClick={() => update_form()} disabled={action === 'form-updating' ? true : false} >{action === 'form-updating' ? <CircularProgress color="inherit" size={24} /> : 'Update'}</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={open === 'opencert'} fullWidth maxWidth='md' onClose={() =>  setOpen('')}>
                <DialogTitle>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography fontWeight={500} color={'info.main'}>Workshop certificate</Typography>

                        <Box>
                            <Chip sx={{mr: 1}} icon={<Print/>} label='Cert' clickable onClick={() => print_cert()} />
                            <Chip icon={<Print/>} label='Scho' clickable onClick={() => print_scho()} />
                        </Box>
                    </Stack>
                </DialogTitle><Divider/>
                <DialogContent>
                    <Box ref={area2} sx={{position: 'relative'}} >
                        <img src="/images/workshop-certificate.png" style={{width: '295mm', height: '207mm'}} />
                        <Box sx={{position: 'absolute', top: 400, width: '297mm',textAlign: 'center'}} >
                            <Typography fontSize={40} textTransform={'uppercase'} fontWeight={'bold'} >{cert.name}</Typography>
                        </Box>
                        <Box sx={{position: 'absolute', top: 570, width: '294mm',textAlign: 'center'}} >
                            <Typography fontSize={12} textTransform={'uppercase'} fontWeight={600} >{cert.aadhaar}</Typography>
                        </Box>
                        <Typography sx={{position: 'absolute', top: 682, left: 265}} fontSize={14} fontWeight={600} color={'gray'} >14<sup>th</sup> June 2024</Typography>
                    </Box>

                    <Box ref={area3} sx={{position: 'relative'}} >
                        <img src="/images/scholarship.png" style={{width: '295mm', height: '207mm'}} />
                        <Box sx={{position: 'absolute', top: 365, width: '297mm'}} >
                            <Typography fontSize={30} textTransform={'uppercase'} fontWeight={'bold'} ml={8} >{cert.name}</Typography>
                        </Box>
                        <Box sx={{position: 'absolute', top: 430, width: '297mm'}} >
                            <Typography fontSize={14} ml={8} fontWeight={'bold'} color={'gray'} >Aadhaar number: {cert.aadhaar}</Typography>
                        </Box>
                        <Typography sx={{position: 'absolute', top: 640, left: 690}} fontSize={18} fontWeight={600} color={'white'} >14<sup>th</sup> June 2024</Typography>
                    </Box>
                </DialogContent>
            </Dialog>
            <Container>
                <FormControl variant="standard" fullWidth sx={{mb: 2}} >
                    <InputLabel>Search student by name</InputLabel>
                    <Input
                        value={searchquery}
                        onChange={(event) => setSearchquery(event.target.value)}
                        type='text'
                        onKeyUp={(event) => searchquery.length > 0 && event.key === 'Enter' && search_student()}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton disabled={searchquery.length > 0 ? false : true} onClick={() => search_student()} ><Search/></IconButton>
                        </InputAdornment>
                        }
                    />
                </FormControl>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" >#</TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Gender</TableCell>
                                <TableCell align="left">Mobile</TableCell>
                                <TableCell align="left">Birth</TableCell>
                                <TableCell align="left">Meal</TableCell>
                                <TableCell align="right">Ticket</TableCell>
                                <TableCell align="right">Certificate</TableCell>
                                <TableCell align="right">Edit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { wdata.status !== false && wdata.map((row, i) =>
                            <TableRow key={i}>
                                <TableCell align="left">{wdata.length-i}</TableCell>
                                <TableCell align="left">{row.name}</TableCell>
                                <TableCell align="left">{row.gender}</TableCell>
                                <TableCell align="left">{row.mobile}</TableCell>
                                <TableCell align="left">{new Date(row.birth).toLocaleDateString(undefined, {day: '2-digit', month: 'short', year: 'numeric'})}</TableCell>
                                <TableCell align="left"><Chip variant="contained" color={row.meal === 'Veg' ? 'success' : 'error'} label={row.meal} /></TableCell>
                                <TableCell align="right"><IconButton color="warning" onClick={() => open_print(row.name, row.gender, row.birth, row.meal, row.tableid)} ><Print/></IconButton></TableCell>
                                <TableCell align="right"><IconButton color="warning" onClick={() => open_cert(row.mobile)} ><Print/></IconButton></TableCell>
                                <TableCell align="right"><Chip clickable variant="contained" icon={<Edit/>} label='Edit' color="info" onClick={() => open_form(row.mobile)} /></TableCell>
                            </TableRow> ) }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </Box>
    );
}