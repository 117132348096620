import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'
import Footer from "./footer";

// MUI
import { Avatar, Box, Button, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Stack, TextField, Typography } from '@mui/material'
import { Call, Facebook, Instagram, Mail, WhatsApp, YouTube } from '@mui/icons-material'
import { TypeAnimation } from "react-type-animation";


export default function Home({snackbar}){
    let navi = useNavigate()
    const [open, setOpen] = useState('')

    const [name, setName] = useState('')
    const [ins, setIns] = useState('')
    const [add, setAdd] = useState('')
    const [mob, setMob] = useState('')

    const send = () => {
        if(name !== '' && ins !== '' && add !== '' && mob !== ''){
            window.location.href = `https://wa.me/917631473503/?text=Hi! This is ${name}. Founder of ${ins}. I am interested on your franchise. Please contact me on ${mob}. Institute address: ${add}`
        } else {
            snackbar('All fields are mandatory')
        }
    }

    return(
        <><Box sx={{background: '#000000', color: 'secondary.main'}} pb={3} >
            <Dialog open={open === 'openfr' ? true : false} fullWidth maxWidth='xs' onClose={() => setOpen('')} >
                <DialogTitle>Apply for franchise</DialogTitle>
                <Divider/>
                <DialogContent>
                    <Stack spacing={2}>
                        <TextField variant="outlined" type="text" label='Your name*' value={name} onChange={(event) => setName(event.target.value)} />
                        <TextField variant="outlined" type="text" label='Institute name*' value={ins} onChange={(event) => setIns(event.target.value)} />
                        <TextField variant="outlined" type="text" label='Full address*' value={add} onChange={(event) => setAdd(event.target.value)} />
                        <TextField variant="outlined" type="number" label='Mobile number*' value={mob} onChange={(event) => setMob(event.target.value)} />
                    </Stack>
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setOpen('')} >Close</Button>
                    <Button variant="contained" color="success" onClick={() => send()} >Send</Button>
                </DialogActions>
            </Dialog>
            <Box sx={{backgroundImage: "url('/images/bg1.png')"}} className='bg1' >
                <Container sx={{height: '90vh'}} >
                    <Stack justifyContent={'center'} alignItems={'center'} sx={{height: '90vh'}} >
                        <Box textAlign={'center'} >
                            <Chip className="zooming-image" label='Apply for franchise' variant="contained" color="error" clickable size="small" onClick={() => setOpen('openfr')} />
                            <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={1} useFlexGap flexWrap={'wrap'} >
                                <Typography fontSize={{md: 60, xs: 40}} color={'white'} fontWeight={'bolder'} >Where</Typography>
                                <Typography fontSize={{md: 60, xs: 40}} color={'white'} fontWeight={'bolder'} className="shaking-text" >Bytes Dance</Typography>
                                <Typography fontSize={{md: 60, xs: 40}} color={'white'} fontWeight={'bolder'} >and Algorithms</Typography>
                            </Stack>
                            <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={1} >
                                <Typography fontSize={{md: 60, xs: 40}} color={'white'} fontWeight={'bolder'} >Go</Typography>
                                <Typography fontSize={{md: 60, xs: 40}} color={'white'} fontWeight={'bolder'} className="flipped-text" >Mad!</Typography>
                            </Stack>
                            <Typography color={'white'} >Let's learn</Typography>
                            <TypeAnimation sequence={[
                                'Web Development',
                                500,
                                'Diploma Course', //  Continuing previous Text
                                500,
                                'Artificial Intelligence',
                                500,
                            ]}
                            style={{ fontSize: '2em', fontFamily: 'monospace', color: 'white', fontWeight: 'bold'}}
                            repeat={Infinity}
                            />

                            <Stack direction={'row'} justifyContent={'center'} mt={3} spacing={1} >
                                <IconButton color="secondary" size="large" onClick={() => window.location.href = 'tel: 7808426759'} ><Call/></IconButton>
                                <IconButton color="secondary" size="large" onClick={() => window.location.href = 'https://wa.me/917808426759'} ><WhatsApp/></IconButton>
                                <IconButton color="secondary" size="large" ><Mail/></IconButton>
                                <IconButton color="secondary" size="large" onClick={() => window.location.href="https://www.instagram.com/spywarecomputers/"} ><Instagram/></IconButton>
                                <IconButton color="secondary" size="large" onClick={() => window.location.href = 'https://youtube.com/@spywarecomputers?si=Yk4z9n4dNQu0MCcN'} ><YouTube/></IconButton>
                            </Stack>
                        </Box>
                    </Stack>
                </Container>
            </Box>
            <Container>
                <Grid container spacing={2} >
                    <Grid item xs={12} md={5} >
                        <Box sx={{border: 'thin solid', borderColor: 'secondary.main', height: '100%'}} padding={2} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={3} >
                            <img src="/images/gif3.gif" alt="gif 3" style={{width: 300}} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={7} >
                        <Box sx={{border: 'thin solid', borderColor: 'secondary.main', height: '100%'}} padding={2} display={'flex'} justifyContent='flex-start' alignItems={'center'} borderRadius={3} >
                            <Box>
                                <Typography color={'error'} fontSize={{xs: 30, md: 40}} fontWeight={'bold'} className="anno" >// Spyware Computer Education_.</Typography>
                                <Typography className="anno" fontSize={18} mt={3} >“Welcome to Spyware Institute, a cutting-edge hub of technological education dedicated to shaping the next generation of digital pioneers. Established with a vision to bridge the gap between curiosity and competence, our institute is committed to providing a transformative learning experience.”</Typography>

                                <Stack direction={'row'} mt={1} alignItems={'center'} >
                                    <Typography fontSize={20} fontWeight={'bold'} color={'white'} className="anno" >Follow us on: </Typography>
                                    <IconButton color="secondary" onClick={() => window.location.href="https://www.instagram.com/spywarecomputers/"} ><Instagram/></IconButton>
                                    <IconButton color="secondary" onClick={() => window.location.href = 'https://youtube.com/@spywarecomputers?si=Yk4z9n4dNQu0MCcN'} ><YouTube/></IconButton>
                                    <IconButton color="secondary" onClick={() => window.location.href = 'https://wa.me/917808426759'} ><WhatsApp/></IconButton>
                                </Stack>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Box sx={{backgroundImage: "url('/images/gif4.gif')"}} height={400} mt={8} className='bg1' display={'flex'} justifyContent={'center'} alignItems={'center'} padding={2} >
                <Box textAlign={'center'} >
                    <Typography color={'secondary'} fontSize={25} fontWeight={'bold'} className="anno" >Print_f</Typography>
                    <Typography color={'secondary'} fontSize={{xs: 30, md: 50}} fontWeight={'bold'} className="anno" >“One Destination for your</Typography>
                    <Typography color={'secondary'} fontSize={{xs: 30, md: 50}} fontWeight={'bold'} className="anno" >IT education!”</Typography>
                    <Box textAlign={'center'} >
                        <Button color="secondary" variant="contained" startIcon={<Call/>} size="large" onClick={() => window.location.href = 'tel: 7808426759'} >Call now</Button>
                    </Box>
                </Box>
            </Box>

            <Container>
                <Stack mt={7} direction={{xs: 'column', md: 'row'}} spacing={2} >
                    <Box borderRadius={2} overflow={'hidden'} sx={{border: 'thin solid', borderColor: 'secondary.main', width: {md: '40%'}}} padding={2} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} >
                        <img src="/images/img2.png" alt="Spyware Comptuter Education" className="zooming-image" style={{width: 300}} />
                    </Box>
                    <Box borderRadius={2} overflow={'hidden'} sx={{border: 'thin solid', borderColor: 'secondary.main', width: {md: '60%'}}} padding={2} display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} >
                        <Box>
                            <Typography color={'error'} fontSize={{xs: 30, md: 35}} fontWeight={'bold'} className="anno" >Why Spyware Computer Education?_;</Typography>
                            <Typography className="anno" fontSize={18} mt={1} >A dynamic learning hub where innovation meets education. Join us to acquire in-demand knowledge, hone your expertise, and pave the way for a successful career in the ever-evolving world of technology.</Typography>
                            <Typography color={'error'} mt={3} fontSize={{xs: 25, md: 30}} fontWeight={'bold'} className="anno" >We are full of...</Typography>
                            <Stack direction={'row'} useFlexGap flexWrap={'wrap'} mt={1} spacing={2} >
                                <Button variant="outlined" color='secondary' >Digital classroom</Button>
                                <Button variant="outlined" color='secondary' >Structured classroom</Button>
                                <Button variant="outlined" color='secondary' >Computer Lab</Button>
                                <Button variant="outlined" color='secondary' >Certified courses</Button>
                                <Button variant="outlined" color='secondary' >Free WIFI</Button>
                                <Button variant="contained" color='secondary' >Join now</Button>
                            </Stack>
                        </Box>
                    </Box>
                </Stack>

                <Box mt={7} >
                    <Typography textAlign={'center'} color={'error'} fontSize={{xs: 30, md: 35}} fontWeight={'bold'} className="anno" >{'<?php ‘Most Interested Courses’ ?>'}</Typography>

                    <Stack direction={{xs: 'column', md: 'row'}} spacing={2} mt={3} >
                        <Box borderRadius={2} sx={{width: {md: '30%'}, border: 'thin solid', borderColor: 'secondary.main'}} padding={2} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} >
                            <Box textAlign={'center'} pt={2} pb={2} >
                                <Typography color={'white'} fontSize={12} >import</Typography>
                                <Typography fontSize={25} fontWeight={'bold'} color={'white'} letterSpacing={3} >DCA</Typography>
                                <Typography color={'white'} >echo 'Diploma in Computer Application'</Typography>
                            </Box>
                        </Box>
                        <Box borderRadius={2} sx={{width: {md: '37%'}, border: 'thin solid', borderColor: 'secondary.main'}} padding={2} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} >
                            <Box textAlign={'center'} pt={2} pb={2} >
                                <Typography color={'white'} fontSize={12} >import</Typography>
                                <Typography fontSize={25} fontWeight={'bold'} color={'white'} letterSpacing={3} >ADCA</Typography>
                                <Typography color={'white'} >echo ‘Advanced Diploma in Computer Application’</Typography>
                            </Box>
                        </Box>
                        <Box borderRadius={2} sx={{width: {md: '33%'}, border: 'thin solid', borderColor: 'secondary.main'}} padding={2} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} >
                            <Box textAlign={'center'} pt={2} pb={2} >
                                <Typography color={'white'} fontSize={12} >import</Typography>
                                <Typography fontSize={25} fontWeight={'bold'} color={'white'} letterSpacing={3} >MDOM</Typography>
                                <Typography color={'white'} >echo ’Master Diploma in Office Automation’</Typography>
                            </Box>
                        </Box>
                    </Stack>
                    <Stack direction={{xs: 'column', md: 'row'}} spacing={2} mt={3} >
                        <Box borderRadius={2} sx={{width: {md: '36%'}, border: 'thin solid', borderColor: 'secondary.main'}} padding={2} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} >
                            <Box textAlign={'center'} pt={2} pb={2} >
                                <Typography color={'white'} fontSize={12} >import</Typography>
                                <Typography fontSize={25} fontWeight={'bold'} color={'white'} letterSpacing={3} >Accounting</Typography>
                                <Typography color={'white'} >echo ‘With tally + GST to detailed in account’</Typography>
                            </Box>
                        </Box>
                        <Box borderRadius={2} sx={{width: {md: '34%'}, border: 'thin solid', borderColor: 'secondary.main'}} padding={2} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} >
                            <Box textAlign={'center'} pt={2} pb={2} >
                                <Typography color={'white'} fontSize={12} >import</Typography>
                                <Typography fontSize={25} fontWeight={'bold'} color={'white'} letterSpacing={3} >Website Dev.</Typography>
                                <Typography color={'white'} >echo ‘Basic website to modern application development’</Typography>
                            </Box>
                        </Box>
                        <Box sx={{width: {md: '30%'}}} >
                            <Box borderRadius={2} textAlign={'center'} mb={2.7} sx={{border: 'thin solid', borderColor: 'secondary.main'}} p={3} >
                                <Typography color={'white'} fontSize={12} >Fill enthusiastic with us because we are</Typography>
                                <Typography color={'white'} fontSize={14} fontWeight={'bold'} >SpyWares</Typography>
                            </Box>
                            <Button variant="contained" size="large" color="secondary" sx={{width: '100%', pt: 2, pb: 2}} onClick={() => window.location.href = 'tel: 7808426759'} >Make a call</Button>
                        </Box>
                    </Stack>
                </Box>

                <Grid container sx={{border: 'thin dashed', borderColor: 'secondary.main', mt: 10, padding: 1}} >
                    <Grid pt={3} pb={3} item xs={12} md={8} mx={'auto'}>
                        <Stack direction={{xs: 'column', md: 'row'}} spacing={1.5} alignItems={'center'} >
                            <Avatar src="/images/director.png" sx={{width: 200, height: 200, borderRadius: 2}} />
                            <Box sx={{width: '100%'}} >
                                <Typography fontSize={20} fontWeight={600} >A message from director...</Typography>
                                <Typography mt={2} fontSize={12} color={'secondary.main'}>
                                Welcome to Spyware Computer Education, where we are committed to shaping the future of IT professionals through comprehensive training and education. As the Director of this esteemed institute, it gives me great pleasure to extend a warm welcome to all aspiring learners.
                                </Typography>

                                <Stack mt={2} direction={{xs: 'column-reverse', md: 'row'}} justifyContent={{md: 'space-between', xs: 'normal'}} alignItems={{md: 'center', xs: 'left'}} spacing={2} >
                                    <Box>
                                        <Typography fontSize={14} >Get in touch with him</Typography>
                                        <Stack mt={1} direction={'row'} spacing={1.2}>
                                            <IconButton color="secondary" sx={{background: '#C6FCD8'}} onClick={() => window.location.href="https://www.instagram.com/vibhashjhaa780/"} ><Instagram/></IconButton>
                                            <IconButton color="secondary" sx={{background: '#C6FCD8'}} onClick={() => window.location.href="https://www.facebook.com/vibhash.jha.3"} ><Facebook/></IconButton>
                                            <IconButton color="secondary" sx={{background: '#C6FCD8'}} onClick={() => window.location.href="https://wa.me/917808426759"} ><WhatsApp/></IconButton>
                                        </Stack>
                                    </Box>

                                    <Box textAlign={{md: 'right', xs: 'left'}} >
                                        <Typography fontSize={10} >Director</Typography>
                                        <Typography fontSize={14} color={'error.main'} >Vibhash Kumar Jha</Typography>
                                    </Box>
                                </Stack>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
                
                <Typography textAlign={'center'} fontSize={{md: 20, xs: 15}} fontWeight={500} mt={7} color={'white'} >{'{ Be the family of }'}</Typography>
                <Typography textAlign={'center'} fontSize={{md: 50, xs: 30}} fontWeight={'bolder'} color={'secondary.main'} letterSpacing={3} className="blinking-text cert-font" >{'|| SPYWARES ||'}</Typography>
            </Container>

        </Box><Footer/></>
    );
}