import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles';

// MUI
import { Alert, Avatar, Badge, Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, Divider, Grid, IconButton, LinearProgress, Paper, Stack, TextField, Typography } from '@mui/material'
import { Add, CameraAlt, Delete, Edit } from '@mui/icons-material'
import axios from "axios";


export default function Franchises({snackbar, apiadmin}){

    const navi = useNavigate()
    const [action, setAction] = useState('')
    const [open, setOpen] = useState(false)
    const [franchiseid, setFranchiseid] = useState('')

    useEffect(() => {
        getFranchises()
    }, [])

    const [franchises, setFranchises] = useState([])
    const [fLoading, setFloading] = useState(false)
    const getFranchises = () => {
        setFloading(true)
        var formData = new FormData()
        formData.append('get-franchises', '')
        fetch(apiadmin, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setFloading(false)
            data.status == false ? setFranchises(data) : setFranchises(data.data)
        })
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const add_logo = (franchiseid, event) => {
        snackbar('Uploading...')
        setAction('uploadinglogo')
        var formData = new FormData()
        formData.append('add-franchise-logo', franchiseid)
        formData.append('image', event.target.files[0])
        axios.post(apiadmin, formData)
        .then(function(res){
            setAction('')
            snackbar(res.data.msg)
            if(res.data.status === true){
                window.location.reload()
            }
        })
    }

    const open_delete = (franchiseid) => {
        setFranchiseid(franchiseid); setOpen(true)
    }
    const delete_franchise = () => {
        setAction('deleting')
        var formData = new FormData()
        formData.append('delete-franchise', franchiseid)
        axios.post(apiadmin, formData)
        .then(function(res){
            setAction(''); setOpen(false)
            snackbar(res.data.msg)
            if(res.data.status === true){
                getFranchises()
            }
        })
    }

    return(
        <Box className='content' mt={4}>
            <Container>
                <Stack direction='row' alignItems='center' justifyContent='space-between' mb={2} >
                    <Typography fontSize={14} fontWeight='bold' >Franchises</Typography>
                    <Box>
                        <Button startIcon={<Add/>} variant="outlined" onClick={() => navi('/controller/franchise-upload/0')} >Add franchise</Button>
                    </Box>
                </Stack>
                { fLoading ? <LinearProgress color="primary" /> : franchises.status == false ? <Alert severity="warning" >{franchises.message}</Alert> :
                <Grid container spacing={2} >
                    { franchises.map((data) =>
                    <Grid item xs={12} md={4} key={data.franchiseid} >
                        <Box padding={1.5} component={Paper} >
                            <Stack mb={3} direction='row' alignItems='center' spacing={2} >
                                <Box>
                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        badgeContent={ <IconButton component='label' disabled={action === 'uploadinglogo' ? true : false} onChange={(event) => add_logo(data.franchiseid, event)} ><CameraAlt /> <VisuallyHiddenInput type="file" accept=".jpeg, .jpg, .png" /></IconButton> } >
                                        <Avatar alt={data.fr_name} sx={{width: 120, height: 120, borderRadius: 5}} src={`/spywareassets/franchise/${data.franchiseid}.jpg`} />
                                    </Badge>
                                </Box>
                                <Box>
                                    <Typography fontWeight='bold' fontSize={15} className="wrap-1" >{data.fr_name}</Typography>
                                    <Typography fontSize={14} color='gray' className="wrap-1" >{data.fr_address}, {data.fr_city}</Typography>
                                </Box>
                            </Stack>
                            <Box mb={2} >
                                <Typography noWrap fontWeight='bold' fontSize={14} >Owned by: {data.fr_owner_name}</Typography>
                                <Typography noWrap fontWeight='bold' fontSize={14} >Email: {data.fr_email}</Typography>
                                <Typography noWrap fontWeight='bold' fontSize={14} >Join date - {data.fr_joined}</Typography>
                            </Box><Divider/>
                            <Stack mt={1} direction='row' alignItems='center' justifyContent='space-between' >
                                <Box>
                                    <IconButton color="error" onClick={() => open_delete(data.franchiseid)} ><Delete/></IconButton>
                                    <IconButton color="primary" onClick={() => navi(`/controller/franchise-upload/${data.franchiseid}`)} ><Edit/></IconButton>
                                </Box>
                                {data.fr_status == 'active' ? <Typography color='green' fontSize={14} fontWeight='bold' >Active</Typography> : <Typography color='orange' fontSize={14} fontWeight='bold' >Pending</Typography> }
                            </Stack>
                        </Box>
                    </Grid> )}
                </Grid> }
            </Container>

            <Dialog open={open} fullWidth maxWidth='xs' onClose={() => setOpen(false)} >
                <DialogContent>
                    <Typography fontWeight={'bold'} fontSize={25} color={'darkorange'} >Do you want to delete this franchise?</Typography>
                    <Typography fontSize={14} color={'gray'} >Remember, this process will delete all data related to this franchise. Like student.</Typography>

                    <Typography fontSize={14} color={'gray'} mt={2} >To delete this franchise type '<i>delete</i>' below.</Typography>
                    <TextField fullWidth variant="outlined" type="text" size="small" placeholder="type 'delete' here" value={action} onChange={(event) => setAction(event.target.value)} onKeyUp={(event) => event.key === 'Enter' && action === 'delete' && delete_franchise()} />
                </DialogContent><Divider/>
                <DialogActions>
                    <Button variant="outlined" >Close</Button>
                    <Button variant="contained" color="error" disabled={action === 'delete' ? false : action === 'deleting' ? true : true} onClick={() => delete_franchise()} >{action === 'deleting' ? <CircularProgress color="inherit" size={24} /> : 'Delete'}</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );

}