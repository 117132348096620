import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom'
import Back from "../bar";

// MUI
import { Box, Button, CircularProgress, Container, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material'
import {  } from '@mui/icons-material'

export default function FranchiseUpload({snackbar, apiadmin}){
    let navi = useNavigate()
    const { franchiseid } = useParams()

    const [oname, setOname] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [state, setState] = useState('')
    const [dist, setDist] = useState('')
    const [city, setCity] = useState('')
    const [code, setCode] = useState('')
    const [joindate, setJoindate] = useState('')
    const [status, setStatus] = useState('active')

    useEffect(() => {
        var formData = new FormData()
        formData.append('get-franchise-detail', franchiseid)
        fetch(apiadmin, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.status == true){
                setOname(data.data.fr_owner_name); setEmail(data.data.fr_email); setMobile(data.data.fr_mobile); setName(data.data.fr_name); setAddress(data.data.fr_address); setState(data.data.fr_state); setDist(data.data.fr_dist); setCity(data.data.fr_city); setCode(data.data.fr_code); setJoindate(data.data.fr_joined); setStatus(data.data.fr_status)
            }
        })
    }, [franchiseid])

    const [uploading, setUploading] = useState(false)
    const upload = () => {
        setUploading(true)
        var formData = new FormData()
        formData.append('add-franchise', franchiseid)
        formData.append('oname', oname);formData.append('email', email);formData.append('mobile', mobile); formData.append('name', name); formData.append('address', address);formData.append('state', state);formData.append('dist', dist);formData.append('city', city);formData.append('code', code);formData.append('joindate', joindate);formData.append('status', status)
        fetch(apiadmin, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setUploading(false)
            if(data.status == false){
                snackbar(data.message)
            } else {
                navi(-1)
            }
        })
    }

    return(
        <Box className='content' >
            <Container>
                <Back text='Franchise upload'/>

                <Stack direction={{xs: 'column', md: 'row'}} spacing={2} >
                    <TextField fullWidth variant="outlined" label='Owner name*' type="text" value={oname} onChange={(event) => setOname(event.target.value)} />
                    <TextField fullWidth variant="outlined" label='Email*' type="text" value={email} onChange={(event) => setEmail(event.target.value)} />
                    <TextField fullWidth variant="outlined" label='Mobile*' type="text" value={mobile} onChange={(event) => setMobile(event.target.value)} />
                </Stack>
                <Stack direction={{xs: 'column', md: 'row'}} spacing={2} mt={2} >
                    <TextField fullWidth variant="outlined" label='Institute name*' type="text" value={name} onChange={(event) => setName(event.target.value)} />
                    <TextField fullWidth variant="outlined" label='Institute address*' type="text" value={address} onChange={(event) => setAddress(event.target.value)} />
                </Stack>
                <Stack direction={{xs: 'column', md: 'row'}} spacing={2} mt={2} >
                    <TextField fullWidth variant="outlined" label='State' type="text" value={state} onChange={(event) => setState(event.target.value)} />
                    <TextField fullWidth variant="outlined" label='District' type="text" value={dist} onChange={(event) => setDist(event.target.value)} />
                    <TextField fullWidth variant="outlined" label='City' type="text" value={city} onChange={(event) => setCity(event.target.value)} />
                </Stack>
                <Stack direction={{xs: 'column', md: 'row'}} spacing={2} mt={2} >
                    <TextField fullWidth variant="outlined" label='Code' type="text" value={code} onChange={(event) => setCode(event.target.value)} />
                    <TextField fullWidth variant="outlined" helperText='Join date*' type="date" value={joindate} onChange={(event) => setJoindate(event.target.value)} />
                    <FormControl fullWidth >
                        <InputLabel>Status*</InputLabel>
                        <Select label='Status' value={status} onChange={(event) => setStatus(event.target.value)} >
                            <MenuItem value='active' >Active</MenuItem>
                            <MenuItem value='pending' >Pending</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <Stack textAlign='right' spacing={1} direction='row' justifyContent='end' mt={2} >
                    <Button variant='outlined' onClick={() => navi(-1)} >Back</Button>
                    <Button variant="contained" sx={{width: '100%'}} onClick={() => upload()} disabled={uploading} >{uploading ? <CircularProgress color="inherit" size={24} /> : 'Upload'}</Button>
                </Stack>
            </Container>
        </Box>
    );
}