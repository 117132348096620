import React, { useEffect, useRef, useState } from "react";
import { useParams } from 'react-router-dom'
import PublicHeader from './header'
import QRCode from "react-qr-code";

// MUI
import { Box, Container, Grid, Typography } from '@mui/material'
import {  } from '@mui/icons-material'

export default function TypingCertificate({apipublic}){
    const { studentid } = useParams()
    const { courseid } = useParams()
    const area = useRef()

    const [info, setInfo] = useState([])

    useEffect(() => {
        var formData = new FormData()
        formData.append('get-issued-certificate', studentid)
        formData.append('courseid', courseid)
        fetch(apipublic, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.status !== false){
                window.title = data.cert.c_title
                setInfo(data.data)
            }
        })
    }, [])

    return(
        <Box>
        <PublicHeader text='Certificate' printarea={area} isprint={true} mobile={info.mobile} /> <Container>
            <Grid container mt={10} >
                <Grid item xs={12} md={12} mx='auto' overflow='scroll' >
                    <Box ref={area} sx={{position: 'relative', width: '295mm', height: '208mm', mx:'auto'}} >
                        <img src="/images/certificate.webp" alt="Computer certificate" style={{width: '295mm', height: '208mm'}} />
                        
                        <QRCode size={120} style={{ position: 'absolute', top: '18mm', right: '6.5mm' }} value={`https://spywarecomputers.com/verify?enrollment=${info.enrollment_no}`} viewBox={`0 0 256 256`} />
                        
                        <Box sx={{position: 'absolute', top: 305, left: 168, width: '243mm'}} >
                            <Typography fontSize={22} letterSpacing={1} fontWeight={600} color={'#007fff'} className="cert-font" >Enrollment No.: {info.enrollment_no}  | Certificate No.: {info.certificate_no} </Typography>

                            <Typography mt={2} fontSize={18} fontWeight={400} lineHeight={1.7} className="cert-font" >This is to certify that <span className="cert-font" style={{fontWeight: 800, color: '#007fff'}} >{info.name}</span>. D/o, S/o, Shri, <span className="cert-font" style={{fontWeight: 800, color: '#007fff'}} >{info.guardian}</span> has successfully completed the <span className="cert-font" style={{fontWeight: 800, color: '#007fff'}} >{info.c_title} ({info.c_name})</span> course in the duration of <span className="cert-font" style={{fontWeight: 800, color: '#007fff'}} >{info.c_duration} months</span> and has been awarded his proficiency certificate from an authorized study center <span className="cert-font" style={{fontWeight: 800, color: '#007fff'}} >{info.fr_name}, {info.fr_city}</span>. An authorized study center of Spyware Computer (OPC) Pvt. Ltd. </Typography>

                            <Typography className="cert-font" mt={2} fontWeight={400} fontSize={18} >He or she has completed the above-mentioned course with the</Typography>
                            <Typography fontSize={18} className="cert-font" >typing speed: <span className="cert-font" style={{fontWeight: 800, color: '#007fff'}} >{info.obtained}</span></Typography>

                            <Typography mt={2} fontSize={18} className="cert-font" >Issue date & place: <span style={{fontWeight: 800, color: '#007fff'}} className="cert-font" >{info.issued_on}/ {info.fr_state}</span></Typography>
                        </Box>

                    </Box>
                </Grid>
            </Grid>
        </Container></Box>
    );
}