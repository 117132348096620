import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import MyContext from "../context/context";

// MUI
import { Box, Button, CircularProgress, Container, Dialog, DialogContent, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material'
import { CloudUpload } from '@mui/icons-material'
import axios from "axios";
import styled from "@emotion/styled";

export default function AdmissionStudent({snackbar}){
    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    const api = useContext(MyContext)
    const [action, setAction] = useState('')
    const navi = useNavigate()

    const [fr, setFr] = useState([])
    const [cr, setCr] = useState([])
    useEffect(() => {
        axios.get(`${api.api.public}?franchise-and-course`)
        .then(function(res){
            if(res.data.franchise.status === true){
                setFr(res.data.franchise.data)
            } else {
                setFr(res.data.franchise)
            }

            if(res.data.course.status === true){
                setCr(res.data.course.data)
            } else {
                setCr(res.data.course)
            }
        })
    }, [])

    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [father, setFather] = useState('')
    const [mobile, setMobile] = useState('')
    const [birth, setBirth] = useState('')
    const [gender, setGender] = useState('')
    const [address, setAddress] = useState('')
    const [ins, setIns] = useState('')
    const [course, setCourse] = useState('')
    const [otp, setOtp] = useState('')

    const [otpsent, setOtpsent] = useState(false)

    const send_otp = () => {
        setAction('otpsending')
        var formData = new FormData()
        formData.append('verify-student-to-send-otp', email)
        axios.post(api.api.login, formData)
        .then(function(res){
            setAction('')
            snackbar(res.data.msg)
            if(res.data.status === true){
                setOtpsent(true)
            }
        })
    }

    const submit_detail = () => {
        var img = document.getElementById('img')
        setAction('submitting')
        var formData = new FormData()
        formData.append('create-student-account', ins)
        formData.append('email', email); formData.append('name', name); formData.append('guardian', father); formData.append('mobile', mobile); formData.append('birth', birth); formData.append('gender', gender); formData.append('address', address); formData.append('course', course); formData.append('otp', otp); formData.append('img', img.files[0])
        axios.post(api.api.login, formData)
        .then(function(res){
            setAction('')
            snackbar(res.data.msg);
            if(res.data.status === true){
                setAction('submitted')
            }
        })
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });      

    return(
        <Container >
            <Dialog open={action === 'submitted' ? true : false} fullWidth >
                <DialogContent>
                    <Box textAlign={'center'}>
                        <img src="/images/submit.gif" style={{width: 200, height: 200}} />
                    </Box>
                    <Box textAlign={'center'} >
                        <Typography fontSize={50} fontWeight={600} color={'#FF4F00'} >Hurrryyy!</Typography>
                        <Typography color={'#007FFF'} mt={1} fontSize={14} >You have successfully submitted your details.</Typography>
                        <Typography color={'#007FFF'} mt={1} fontSize={14} >We will contact you after verifying your details. Thank you for being the family of SpyWare.</Typography>
                    </Box>
                    <Box textAlign={'center'} mt={2} >
                        <Button variant="outlined" color="error" onClick={() => navi(-1)} >Ok, Go to home</Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <Grid container mt={4} mb={5} >
                <Grid item xs={12} md={10} mx={'auto'} component={Paper} padding={2} mb={2} >
                    <Typography fontSize={14} color={'#007FFF'} >_You are one step away from becoming the family of spyware</Typography>
                    <Typography fontWeight={500} color={'blue'} fontSize={25} mt={1} >Take Admission</Typography>
                </Grid>
                <Grid item xs={12} md={10} mx={'auto'} component={Paper} padding={2} >

                    <Stack direction={'row'} spacing={1} mt={2} >
                        <TextField fullWidth variant="outlined" type="email" label='Enter your email here' disabled={otpsent} value={email} onChange={(event) => setEmail(event.target.value)} />
                        <Button variant="outlined" color="error" sx={{width: 140}} disabled={action === 'otpsending' ? true : otpsent} onClick={() => send_otp()} >{action === 'otpsending' ? <CircularProgress color="inherit" size={24} /> : 'Send OTP'}</Button>
                    </Stack>

                    <Typography mt={3} mb={1} color={'#007FFF'} fontWeight={500} >Personal detail</Typography>
                    <Stack direction={{xs: 'column', md: 'row'}} spacing={1.5} >
                        <TextField fullWidth variant="outlined" type="text" label='Name' value={name} onChange={(event) => setName(event.target.value)} />
                        <TextField fullWidth variant="outlined" type="text" label='Father name' value={father} onChange={(event) => setFather(event.target.value)} />
                        <TextField fullWidth variant="outlined" type="number" label='Mobile' value={mobile} onChange={(event) => setMobile(event.target.value)} />
                    </Stack>
                    <Stack direction={{xs: 'column', md: 'row'}} spacing={1.5} mt={1.5} >
                        <TextField fullWidth variant="outlined" type="date" helperText='Birth date' value={birth} onChange={(event) => setBirth(event.target.value)} />
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Gender"
                            value={gender}
                            onChange={(event) => setGender(event.target.value)}
                            >
                            <MenuItem value={'Male'}>Male</MenuItem>
                            <MenuItem value={'Female'}>Female</MenuItem>
                            <MenuItem value={'Others'}>Others</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>

                    <Typography mt={3} mb={1} color={'#007FFF'} fontWeight={500} >Address</Typography>
                    <TextField fullWidth variant="outlined" type="text" label='Address' value={address} onChange={(event) => setAddress(event.target.value)} />

                    <Typography mt={3} mb={1} color={'#007FFF'} fontWeight={500} >Other details</Typography>
                    <Stack direction={{xs: 'column', md: 'row'}} spacing={1.5} mt={1.5} >
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Institute</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Institute"
                            value={ins}
                            onChange={(event) => setIns(event.target.value)}
                            >
                                {fr.status !== false && fr.map((row, i) =>
                                <MenuItem value={row.franchiseid} key={i} >{row.fr_name}</MenuItem> ) }
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Course</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Course"
                            value={course}
                            onChange={(event) => setCourse(event.target.value)}
                            >
                                {cr.status !== false && cr.map((row, i) =>
                                <MenuItem value={row.courseid} key={i} >
                                    <Box>
                                        <Typography>{row.c_title} - {row.c_duration} Months</Typography>
                                        <Typography fontSize={14} color={'gray'} >{row.c_name}</Typography>
                                    </Box>
                                </MenuItem> ) }
                            </Select>
                        </FormControl>
                        <TextField fullWidth variant="outlined" type="number" label='OTP' value={otp} onChange={(event) => setOtp(event.target.value)} />
                    </Stack>

                    <Typography mt={3} mb={1} color={'#007FFF'} fontWeight={500} >Profile photo</Typography>
                    <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUpload />}
                    >
                    Profile photo
                    <VisuallyHiddenInput type="file" accept=".jpeg, .jpg, .png" id="img" />
                    </Button>

                    <Box textAlign={'center'} mt={3} >
                        <Button sx={{width: '100%'}} variant="contained" color="success" size="large" disabled={action === 'submitting' ? true : false} onClick={() => submit_detail()} >{action === 'submitting' ? <CircularProgress color="inherit" size={24} /> : 'Submit'}</Button>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}