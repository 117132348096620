import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from 'react-router-dom'

// MUI
import { Box, Chip, Container, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { QrCode2 } from '@mui/icons-material'
import axios from "axios";
import MyContext from "../../context/context";

export default function AttendanceFranchise(){
    const api = useContext(MyContext)
    const navi = useNavigate()
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`

    const urldate = new URLSearchParams(window.location.search).get('value')
    
    const [filderdate, setFilterdate] = useState(urldate?urldate:formattedDate)

    useEffect(() => {
        attendace_list(filderdate)
    }, [filderdate])

    const update_url = (event) => {
        navi(`?value=${event.target.value}`, {replace: true})
        setFilterdate(event.target.value)
    }

    const [attendance, setAttendance] = useState([])
    const attendace_list = (filderdate) => {
        axios.get(`${api.api.global}?attendance-list=${filderdate}`)
        .then(function(res){
            if(res.data.status === false){
                setAttendance(res.data)
            } else {
                setAttendance(res.data.data)
            }
        })
    }

    return(
        <Box className='content' >
            <Container>
                <Stack mb={2} direction={'row'} spacing={1} useFlexGap flexWrap={'wrap'} justifyContent={'space-between'} alignItems={'center'}>
                    <Chip label='Attendance history' color="info" variant="outlined" />
                    <NavLink to={'/attendance/scanner'}><Chip label='Make attendance' color="error" clickable icon={<QrCode2 />} /></NavLink>
                    <TextField variant="outlined" type="date" size="small" value={filderdate} onChange={(event) => update_url(event)} />
                </Stack>
                <TableContainer component={Paper} sx={{height: {md: 530, xs: 'auto'}}} >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">In</TableCell>
                                <TableCell align="left">Out</TableCell>
                                <TableCell align="right">Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {attendance.status != false && attendance.map((row, i) => (
                            <TableRow
                            key={i}
                            >
                            <TableCell component="th" scope="row">
                                {(attendance.length)-i}
                            </TableCell>
                            <TableCell align="left">{row.name}</TableCell>
                            <TableCell align="left"><Chip label={new Date(row.at_in).toLocaleTimeString(undefined, {hour: '2-digit', minute: '2-digit', hour12: true})} color="success" /></TableCell>
                            <TableCell align="left"><Chip label={row.at_out ? new Date(row.at_out).toLocaleTimeString(undefined, {hour: '2-digit', minute: '2-digit', hour12: true}) : 'Waiting'} color="warning" /></TableCell>
                            <TableCell align="right"><Chip label={new Date(row.at_in).toLocaleDateString(undefined, {day: '2-digit', month: 'long', year: '2-digit'})} variant="outlined" color="info" /></TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </Box>
    );
}