import React, { useEffect } from "react";
import {  } from 'react-router-dom'
import Footer from "./footer";

// MUI
import { Box, Container, Divider, Grid, Typography } from '@mui/material'
import {  } from '@mui/icons-material'

export default function CoursesList(){

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return(
        <Box sx={{backgroundColor: 'primary.main', color: 'white'}} pt={3} >
            <Container>
                <Grid container spacing={2} pb={3} >
                    <Grid item xs={12} md={10} mx={'auto'} >
                        <Typography fontSize={17} >Technology has become vast and getting updated day by day. Every student should notice these aspects and must adapt and learn them as the field of Computer is becoming competitive regularly. But the problem of bright future of students starts from their choice of career options after 12th. Computer Diploma Courses after 12th is an available option available for students who have completer their 12th standard.</Typography>
                        
                        <Box mt={3} >
                            <Typography fontSize={30} fontWeight={'bold'} color={'secondary.main'} >MS Office Certification Program</Typography><Divider sx={{backgroundColor: 'warning.main'}} />
                            <Typography fontSize={17} mt={1} >MS office is one of the most used applications of Computer. Few of the most used applications of Microsoft office are - MS Word, MS Excel, and  MS Powerpoint  You must have heard about it and probably have worked on them also. In this course, you will learn some of the advanced aspects of these applications.</Typography>
                            <Typography fontSize={17} mt={1} >Hospitality Industry, Restaurants, schools, colleges etc have a huge requirement of MS Office experts for handling huge data.</Typography>
                        </Box>

                        <Box mt={3} >
                            <Typography fontSize={30} fontWeight={'bold'} color={'secondary.main'} >Web Designing & Development</Typography><Divider sx={{backgroundColor: 'warning.main'}} />
                            <Typography fontSize={17} mt={1} >This is also a short-term program of 3 to 6 months but it may be extended to 1 year as Diploma Course. This field of Computer Science is all about the creation and maintenance of websites. Even a small company has its own website as the internet of things is rising rapidly in today's scenario. So, this field obviously has boost.</Typography>
                        </Box>

                        <Box mt={3} >
                            <Typography fontSize={30} fontWeight={'bold'} color={'secondary.main'} >Digital Marketing</Typography><Divider sx={{backgroundColor: 'warning.main'}} />
                            <Typography fontSize={17} mt={1} >Digital Marketing is vast and wide field and also it is one of the unique courses available in computer field. It covers many subfields in it as SEO, Content writing, Social Media, Email marketing, Affiliate marketing, Lead Generation, Analytics, A/B testing, SMO, Brand management etc. This field can be beneficial for you if you want to promote your brand and run a business or explicitly run a business by your own blog etc. After completion of the course, you can get into any of the following career options.</Typography>
                        </Box>

                        <Box mt={3} >
                            <Typography fontSize={30} fontWeight={'bold'} color={'secondary.main'} >Graphic Designing</Typography><Divider sx={{backgroundColor: 'warning.main'}} />
                            <Typography fontSize={17} mt={1} >Graphic Designing Course gives you a platform to show your creativity. So, If you are a creative one, you can use your talent here. Graphic Designing is used everywhere, it has applications in many fields. After completion of the course, you can get into any of the following career options.</Typography>
                        </Box>

                        <Box mt={3} >
                            <Typography fontSize={30} fontWeight={'bold'} color={'secondary.main'} >Mobile Application Development</Typography><Divider sx={{backgroundColor: 'warning.main'}} />
                            <Typography fontSize={17} mt={1} >This is an era of mobile phones and its application. Use of Mobile phones and its application has increased rapidly, these days. The trend of App development has produced a huge demand of Mobile Applications and its development. The short-term diploma course after 12th course in this field has a duration of 6 months, but it may vary from institute to institute. After completion of the course, you can get into any of the following career options.</Typography>
                        </Box>

                        <Box mt={3} >
                            <Typography fontSize={30} fontWeight={'bold'} color={'secondary.main'} >Hardware Engineering</Typography><Divider sx={{backgroundColor: 'warning.main'}} />
                            <Typography fontSize={17} mt={1} >A computer will work properly if its software, as well as its hardware, is in accurate condition. But as like software, hardware also may have issues and it causes a problem for the user. Here, we require a Hardware Engineer.</Typography>
                        </Box>

                    </Grid>
                </Grid>
            </Container><Footer/>
        </Box>
    );
}