import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom'
import MyContext from './../context/context'

// MUI
import { Box, Button, Card, CardContent, Chip, CircularProgress, Container, Dialog, DialogContent, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import { CopyAll, Download, WhatsApp } from '@mui/icons-material'
import axios from "axios";
import html2canvas from "html2canvas";

export default function Workshop({snackbar}){
    const api = useContext(MyContext)
    const [action, setAction] = useState('')
    const [open, setOpen] = useState('')
    const navi = useNavigate()
    var url = new URLSearchParams(window.location.search).get('workshop-form-detail')

    const [name, setName] = useState('')
    const [gender, setGender] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [birth, setBirth] = useState('')
    const [meal, setMeal] = useState('')
    const [status, setStatus] = useState('')

    const [submitted, setSubmitted] = useState(false)
    const [event, setEvent] = useState(true)

    useEffect(() => {
        get_form_detail(event)
    }, [event])

    const get_form_detail = (event) => {
        setOpen('dialog')
        axios.get(`${api.api.public}?workshop-form-detail=${url?url:mobile}`)
        .then(function(res){
            
            if(res.data.status === true){
                setName(res.data.data.name); setMobile(res.data.data.mobile); setStatus(res.data.data.status); setSubmitted(true);
                setOpen('')
            } else {
                event === true ? setOpen('dialog') : setOpen('')
            }
        })
    }

    const submit_form = () => {
        setAction('submitting')
        var formData = new FormData()
        formData.append('workshop-form', email)
        formData.append('name', name)
        formData.append('gender', gender)
        formData.append('mobile', mobile)
        formData.append('birth', birth)
        formData.append('meal', meal)
        axios.post(api.api.public, formData)
        .then(function(res){
            setAction('')
            snackbar(res.data.msg)
            res.data.status === true && setSubmitted(true); navi(`?workshop-form-detail=`+mobile)
            if(res.data.form === true){
                setName(res.data.data.name)
                setMobile(res.data.data.mobile)
                setStatus(res.data.data.status)
            }
        })
    }



    const handleCopy = (event) => {
        navigator.clipboard.writeText(event)
        .then(() => snackbar('Copied to clipboard'))
        .catch(() => snackbar('Failed to copy'));
    };

    const divRef = useRef(null);
    const handleDownload = () => {
        // Use html2canvas to capture the div content as an image
        html2canvas(divRef.current).then(canvas => {
        // Convert canvas to image data
        const imageData = canvas.toDataURL('image/jpg');

        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = imageData;
        link.download = 'workshop_payment_QR.jpg';
        document.body.appendChild(link);
        
        // Trigger the download
        link.click();

        // Clean up
        document.body.removeChild(link);
        });
    };

    return(
        <Box pb={10} >
            <Container>
                <Grid container>
                    <Grid mt={3} item xs={12} md={6} mx={'auto'} >
                        <Dialog open={open === 'dialog' ? true : false} fullWidth maxWidth='xs'>
                            <DialogContent>
                                <Typography mb={1} color={'warning.main'} fontWeight={500} >Please enter your mobile number</Typography>
                                <TextField variant="outlined" fullWidth label='Mobile' value={mobile} onChange={(event) => setMobile(event.target.value)} />
                                <Box mt={2} textAlign={'center'} >
                                    <Button sx={{width: '100%'}} color="info" variant="contained" onClick={() => get_form_detail(false)} >Next</Button>
                                </Box>
                            </DialogContent>
                        </Dialog>
                        <Card>
                            <CardContent>
                                <Box textAlign={'center'}>
                                    <Typography fontSize={{md: 40, xs: 25}} fontWeight={800} letterSpacing={3} className="wgra1" >SCREENLESS WONDERS</Typography>
                                    <Typography fontSize={{md: 40, xs: 25}} fontWeight={800} letterSpacing={1} className="wgra2" >& A.I. FRONTIERS</Typography>
                                    <Divider/>
                                    <Typography fontSize={{md: 25, xs: 15}} fontWeight={400} color={'#0061ff'} >A tech awerness workshop</Typography>
                                </Box>
                            </CardContent>
                        </Card>
                        { submitted === false ?
                        <Card sx={{mt: 2}} >
                            <CardContent>
                                <Stack spacing={1.2} mt={3} component={'form'} >
                                    <TextField fullWidth variant="outlined" type="text" label='Name' value={name} onChange={(event) => setName(event.target.value)} />
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={gender}
                                            label="Gender"
                                            onChange={(event) => setGender(event.target.value)}
                                        >
                                            <MenuItem value={'Male'}>Male</MenuItem>
                                            <MenuItem value={'Female'}>Female</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <TextField variant="outlined" fullWidth type="email" label='Email' value={email} onChange={(event) => setEmail(event.target.value)} />
                                    <TextField fullWidth variant="outlined" type="number" disabled label='Mobile' helperText="*This will use for 'Admit card' download" value={mobile} onChange={(event) => setMobile(event.target.value)} />
                                    <TextField fullWidth variant="outlined" type="date" helperText='Birth date' value={birth} onChange={(event) => setBirth(event.target.value)} />
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Meal</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={meal}
                                            label="Meal"
                                            onChange={(event) => setMeal(event.target.value)}
                                        >
                                            <MenuItem value={'Veg'}>Veg.</MenuItem>
                                            <MenuItem value={'Non Veg.'}>Non Veg.</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <br/>
                                    <Button type="submit" variant="contained" size="large" color="info" disabled={action === 'submitting' ? true : false} onClick={() => submit_form()} >{action === 'submitting' ? <CircularProgress color="inherit" size={24} /> : 'Submit'}</Button>
                                </Stack>
                            </CardContent>
                        </Card>
                        
                        :

                        <Card sx={{mt: 2}}>
                            <CardContent>
                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} >
                                    <Typography >Name</Typography>
                                    <Typography >{name}</Typography>
                                </Stack>
                                <Stack mt={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'} >
                                    <Typography >Mobile</Typography>
                                    <Typography >{mobile}</Typography>
                                </Stack>
                                <Stack mt={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'} >
                                    <Typography >Status </Typography>
                                    <Typography color={'warning.main'} textTransform={'capitalize'} >{status}</Typography>
                                </Stack>
                                <Stack mt={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'} >
                                    <Typography >Form charge: </Typography>
                                    <Typography fontWeight={500} color={'info.main'} fontSize={20} >&#8377; 450</Typography>
                                </Stack>
                                <Divider>PAY HERE</Divider>
                                <Typography textAlign={'center'} color={'info.main'} fontSize={12} >Banking name: Vikash Kumar Jha</Typography>
                                <Box textAlign={'center'} >
                                    <Box textAlign={'center'} mb={2} >
                                        <img src="/images/payment_qr.jpeg" ref={divRef} style={{width: 200, height: 200}} /><br/>
                                        <Chip label='Download QR' clickable icon={<Download/>} color="info" variant="outlined" onClick={() => handleDownload()} />
                                    </Box>

                                    <Divider>OR</Divider>
                                    
                                    <Box mt={2}>
                                        <Chip label='7808426759@ybl' clickable icon={<CopyAll/>} color="info" onClick={() => handleCopy('7808426759@ybl')} />
                                    </Box>
                                </Box>
                                <Box mt={3} >
                                    <Typography fontSize={14} color={'gray'} >*Please share your payment screenshot here</Typography>
                                    <Box mt={1} textAlign={'center'} >
                                        <Chip label='Click to share' clickable icon={<WhatsApp/>} color="info" onClick={() => window.location.href = `https://wa.me/917631473503?text=Hi Spyware Computer Education! I am `+name+`. I am sharing you my succeed payment screenshot for workshop event. Mobile: `+mobile } />
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card> }
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}