import React, {  } from "react";
import { useNavigate } from 'react-router-dom'
import { useReactToPrint } from "react-to-print";

// MUI
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material'
import { ArrowBack, Print, Share } from '@mui/icons-material'

export default function PublicHeader({text, printarea, isprint, mobile}){
    let navi = useNavigate()
    const print = useReactToPrint({
        content:() => printarea.current
    })

    return(
        <AppBar color='primary' position='sticky' >
            <Toolbar>
                <IconButton edge='start' color="inherit" onClick={() => navi(-1)} ><ArrowBack /></IconButton>
                <Typography flexGrow={1} ></Typography>

                <Typography fontSize={16} >{text}</Typography>
                { isprint ? <IconButton edge='start' color="inherit" sx={{ml: 0}} onClick={() => print()} ><Print /></IconButton> : null }
                { mobile > 0 && <IconButton edge='start' color="inherit" sx={{ml: 0}} onClick={() => window.location.href = `https://wa.me/91${mobile}?text=${window.location.href}`} ><Share /></IconButton> }
            </Toolbar>
        </AppBar>
    );
}