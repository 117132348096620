import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'

// MUI
import { Box, Button, CircularProgress, Container, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, Typography } from '@mui/material'
import { Search } from '@mui/icons-material'

export default function Verification({apipublic, snackbar}){
    const navi = useNavigate()

    useEffect(() => {
        window.scroll(0,0)
    }, [])

    const [working, setWorking] = useState('')
    const [enrollment, setEnrollment] = useState('')
    const verify = () => {
        setWorking(true)
        var formData = new FormData()
        formData.append('verify-certificate', enrollment)
        fetch(apipublic, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setWorking(false)
            if(data.status == false){
                snackbar(data.message)
            } else {
                navi(`/verify?enrollment=${enrollment}`)
            }
        })
    }

    return(
        <Box sx={{bgcolor: '#000000', color: 'white'}} >
            <Container sx={{pb: 20}} >
                <Grid container pt={10} >
                    <Grid item xs={12} md={7} mx='auto'>
                        <Box>
                            <Typography fontSize={40} color='orange' align="center" fontWeight='bold' mb={5} fontFamily='serif' >Verify your Entity!</Typography>
                            <FormControl fullWidth variant="outlined" color="secondary" focused >
                                <InputLabel htmlFor="Enter your enrollment number">Enter your enrollment number</InputLabel>
                                <OutlinedInput
                                    id="Enter your enrollment number"
                                    type={'text'}
                                    onChange={(event) => setEnrollment(event.target.value)}
                                    onKeyUp={(event) => event.key==='Enter' && verify()}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        onClick={() => verify()}
                                        edge="end"
                                        color="secondary"
                                        >
                                            {working ? <CircularProgress color="secondary" size={24} /> : <Search color="error" />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                    sx={{color: 'white'}}
                                    label="Enter your enrollment number"
                                    placeholder="Enrollment number"
                                />
                            </FormControl>
                            <Typography mt={3} textAlign={'center'} fontSize={15} >A dynamic learning hub where innovation meets education. Join us to acquire in-demand</Typography>
                            <Typography textAlign={'center'} fontSize={15}>knowledge, hone your expertise, and pave the way for a successful career </Typography>
                            <Typography textAlign={'center'} fontSize={15} mb={2}>in the ever-evolving world of technology.</Typography>
                        </Box>
                        <Box padding={0.1} sx={{bgcolor: 'secondary.main', width: 200, margin: 'auto'}}/>
                    </Grid>
                </Grid>
                <Box mt={6}>

                    <Stack direction={{xs: 'column', md: 'row'}} spacing={2} mt={3} >
                        <Box borderRadius={2} sx={{width: {md: '30%'}, border: 'thin solid', borderColor: 'secondary.main'}} padding={2} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} >
                            <Box textAlign={'center'} pt={2} pb={2} >
                                <Typography color={'white'} fontSize={12} >import</Typography>
                                <Typography fontSize={25} fontWeight={'bold'} color={'white'} letterSpacing={3} >DCA</Typography>
                                <Typography color={'white'} >echo 'Diploma in Computer Application'</Typography>
                            </Box>
                        </Box>
                        <Box borderRadius={2} sx={{width: {md: '37%'}, border: 'thin solid', borderColor: 'secondary.main'}} padding={2} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} >
                            <Box textAlign={'center'} pt={2} pb={2} >
                                <Typography color={'white'} fontSize={12} >import</Typography>
                                <Typography fontSize={25} fontWeight={'bold'} color={'white'} letterSpacing={3} >ADCA</Typography>
                                <Typography color={'white'} >echo ‘Advanced Diploma in Computer Application’</Typography>
                            </Box>
                        </Box>
                        <Box borderRadius={2} sx={{width: {md: '33%'}, border: 'thin solid', borderColor: 'secondary.main'}} padding={2} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} >
                            <Box textAlign={'center'} pt={2} pb={2} >
                                <Typography color={'white'} fontSize={12} >import</Typography>
                                <Typography fontSize={25} fontWeight={'bold'} color={'white'} letterSpacing={3} >MDOM</Typography>
                                <Typography color={'white'} >echo ’Master Diploma in Office Automation’</Typography>
                            </Box>
                        </Box>
                    </Stack>
                    <Stack direction={{xs: 'column', md: 'row'}} spacing={2} mt={3} >
                        <Box borderRadius={2} sx={{width: {md: '36%'}, border: 'thin solid', borderColor: 'secondary.main'}} padding={2} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} >
                            <Box textAlign={'center'} pt={2} pb={2} >
                                <Typography color={'white'} fontSize={12} >import</Typography>
                                <Typography fontSize={25} fontWeight={'bold'} color={'white'} letterSpacing={3} >Accounting</Typography>
                                <Typography color={'white'} >echo ‘With tally + GST to detailed in account’</Typography>
                            </Box>
                        </Box>
                        <Box borderRadius={2} sx={{width: {md: '34%'}, border: 'thin solid', borderColor: 'secondary.main'}} padding={2} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} >
                            <Box textAlign={'center'} pt={2} pb={2} >
                                <Typography color={'white'} fontSize={12} >import</Typography>
                                <Typography fontSize={25} fontWeight={'bold'} color={'white'} letterSpacing={3} >Website Dev.</Typography>
                                <Typography color={'white'} >echo ‘Basic website to modern application development’</Typography>
                            </Box>
                        </Box>
                        <Box sx={{width: {md: '30%'}}} >
                            <Box borderRadius={2} textAlign={'center'} mb={2.7} sx={{border: 'thin solid', borderColor: 'secondary.main'}} p={3} >
                                <Typography color={'white'} fontSize={12} >Fill enthusiastic with us because we are</Typography>
                                <Typography color={'white'} fontSize={14} fontWeight={'bold'} >SpyWares</Typography>
                            </Box>
                            <Button variant="contained" size="large" color="secondary" sx={{width: '100%', pt: 2, pb: 2}} onClick={() => window.location.href = 'tel: 7808426759'} >Make a call</Button>
                        </Box>
                    </Stack>
                </Box>
            </Container>
        </Box>
    );
}