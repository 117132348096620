import React, { useEffect, useRef, useState } from "react";
import { useParams } from 'react-router-dom'
import PublicHeader from './header'

// MUI
import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import {  } from '@mui/icons-material'

export default function ComputerMarksheet({apipublic}){
    const { studentid } = useParams()
    const { courseid } = useParams()
    const area = useRef()

    const [cert, setCert] = useState([])
    const [info, setInfo] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('get-issued-certificate', studentid)
        formData.append('courseid', courseid)
        fetch(apipublic, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.status !== false){
                setCert(data.cert); setInfo(data.data)
            }
        })
    }, [])

    const [marks, setMarks] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('get-subject-marks', studentid)
        formData.append('courseid', courseid)
        fetch(apipublic, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.status !== false){
                setMarks(data.data)
            }
        })
    }, [])

    const grade = Number(Number(cert.obtained_marks)*100/Number(cert.s_fmark))

    return(
        <><PublicHeader text='Marksheet' printarea={area} isprint={true} /> <Container>
            <Grid container mt={10} >
                <Grid item xs={12} md={12} mx='auto' overflow='scroll' >
                    <Box ref={area} sx={{position: 'relative', width: '208mm', height: '295mm', mx:'auto'}} >
                        <img src="/images/marksheet.webp" alt="Computer certificate" style={{width: '208mm', height: '295mm'}} />
                        
                        <Box sx={{position: 'absolute', top: 275, width: '160mm', ml: 20}} >
                            <Stack direction={'row'} justifyContent={'space-between'} >
                                <Typography fontSize={17} fontWeight={600} color={'#007fff'} className="cert-font" >Certificate No. {info.certificate_no}</Typography>
                                <Typography fontSize={17} fontWeight={600} color={'#007fff'} className="cert-font" >Enrollment No. {info.enrollment_no}</Typography>
                            </Stack>

                            <Box mt={1} >
                                <Typography className="cert-font" fontSize={16} >This marksheet is awarded to <span className="cert-font" style={{color: '#007fff', fontWeight: 600}} >{info.name}</span> </Typography>
                                <Typography className="cert-font" fontSize={16} lineHeight={1.6} >
                                    on successful completion of course <span className="cert-font" style={{color: '#007fff', fontWeight: 600}} >{cert.c_title}({cert.c_name})</span> on duration
                                    &nbsp; <span className="cert-font" style={{color: '#007fff', fontWeight: 600}} >{cert.c_duration} Months. </span>
                                    From our authorised training center <span className="cert-font" style={{color: '#007fff', fontWeight: 600}} >{info.fr_name} ({info.fr_code})</span>
                                </Typography>
                            </Box>

                            <Box mt={10} sx={{position: 'absolute', top: 136}} >
                                { marks.map((row, i) =>
                                <Stack direction={'row'} key={i} >
                                    <Box sx={{width: '12mm'}} textAlign={'center'} mb={2} >{i+1}</Box>
                                    <Box sx={{width: '82mm'}} mb={1} >
                                        <Typography ml={3} >Module {i+1}</Typography>
                                        <Typography ml={3} fontSize={10} >{row.s_subject}</Typography>
                                    </Box>
                                    <Box sx={{width: '25mm'}} textAlign={'center'} mb={1} >{row.s_fmark}</Box>
                                    <Box sx={{width: '38mm'}} textAlign={'center'} mb={1} >{row.obtained_marks}</Box>
                                </Stack>) }
                            </Box>
                            
                            <Stack direction={'row'} sx={{position: 'absolute', top: 545}} >
                                <Box sx={{width: '94mm'}} textAlign={'center'} >
                                    <Typography fontWeight={600} fontSize={14} >Total</Typography>
                                </Box>
                                <Box sx={{width: '26mm'}} textAlign={'center'} >
                                    <Typography fontWeight={600} fontSize={14} >{marks.reduce((total, i) => total + Number(i.s_fmark), 0)}</Typography>
                                </Box>
                                <Box sx={{width: '37mm'}} textAlign={'center'} >
                                    <Typography fontWeight={600} fontSize={14} >{marks.reduce((total, i) => total + Number(i.obtained_marks), 0)}</Typography>
                                </Box>
                            </Stack>

                            <Box sx={{position: 'absolute', top:623}} >
                                <Typography className="cert-font" fontSize={14} fontWeight={600} color={'info.main'} >{info.issued_on} / {info.fr_state} </Typography>
                            </Box>
                            <Box sx={{position: 'absolute', top:623, right: 84}} >
                                <Typography className="cert-font" fontSize={14} fontWeight={600} color={'info.main'} >
                                    {info.obtained >= 90 ? 'AA' : info.obtained >= 80 ? 'A+' : info.obtained >= 70 ? 'A' : info.obtained >= 60 ? 'B+' : info.obtained >= 50 ? 'B' : info.obtained >= 40 ? 'D' : 'D'} / {info.obtained} %
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container></>
    );
}