import React, { useState } from "react";
import { NavLink, Outlet, useNavigate } from 'react-router-dom'

// MUI
import { AppBar, Box, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Toolbar } from '@mui/material'
import { Shortcut, Widgets } from '@mui/icons-material'

export default function Header(){
    let navi= useNavigate()
    const [action, setAction] = useState('')
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const links = [
        {name: 'Home', link: '/'},
        {name: 'Gallery', link: '/gallery'},
        {name: 'Courses', link: '/courses'},
        {name: 'Contact', link: '/contact'}
    ]

    const goto = (link) => {
        navi(link)
        setAction('')
    }

    const [menu2, setMenu2] = useState(null)
    const open_menu2 = (event) => {
        setMenu2(event.currentTarget)
    }

    return(
        <Box>
            <AppBar color="primary" position="sticky" elevation={0} sx={{borderBottom: '1px solid', borderColor: 'main.primary'}} >
                <Toolbar sx={{mb: 0.5, mt: 0.5}} >
                    <Box sx={{flexGrow: 1, textAlign: {md: 'left', xs: 'center'}}}>
                        <img src="/text-logo.png" alt="Spyware Computer Education Logo" style={{borderRadius: 0, width: '230px'}} />
                    </Box>
                    
                    <Stack spacing={2} direction='row' sx={{display: {xs: 'none', md: 'flex'}}} >
                        { links.map((row, i) =>
                        <NavLink to={row.link} style={({isActive}) => { return { color: isActive ? '#FF0800' : '#0BDA51', fontWeight: 400 } }} key={i} >{row.name}</NavLink> ) }
                        <NavLink onClick={(event) => open_menu2(event)} style={{cursor: 'pointer', color: '#0BDA51', fontWeight: 400}} >Verification</NavLink>
                        <NavLink onClick={(event) => handleClick(event)} style={{cursor: 'pointer', color: 'white', fontWeight: 400}} >Login</NavLink>
                    </Stack>
                </Toolbar>
            </AppBar>
            <Drawer open={action === 'opendraw' ? true : false} onClose={() => setAction('')} anchor="bottom" PaperProps={{style: {background: '#000000'}}} >
                <List>
                    { links.map((row) =>
                    <ListItem key={row.link} >
                        <ListItemButton onClick={() => goto(row.link)} >
                            <ListItemIcon><Shortcut sx={{color: 'secondary.main'}} /></ListItemIcon>
                            <ListItemText sx={{color: 'secondary.main'}} >{row.name}</ListItemText>
                        </ListItemButton>
                    </ListItem> ) }
                    <ListItem>
                        <ListItemButton>
                            <ListItemIcon><Shortcut sx={{color: 'secondary.main'}} /></ListItemIcon>
                            <ListItemText onClick={(event) => handleClick(event)} sx={{cursor: 'pointer', color: 'white', fontWeight: 'bold'}} >Login</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
            <IconButton sx={{position: 'fixed', display: {xs: 'flex', md: 'none', zIndex: 1000}, bottom: 70, right: 20, backgroundColor: 'error.main'}} size="large" onClick={() => setAction('opendraw')} ><Widgets className="zooming-image" sx={{color: 'cyan'}} /></IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)} >
                <MenuItem onClick={() => navi('/student/home')} >Student</MenuItem>
                <MenuItem onClick={() => window.location.href = 'https://spywarecomputers.com/v1/center/login.php'} >Franchise</MenuItem>
                <MenuItem onClick={() => window.location.href = 'https://spywarecomputers.com/v1/admin/login.php'} >Admin</MenuItem>
            </Menu>

            <Menu
            anchorEl={menu2}
            open={Boolean(menu2)}
            onClose={() => setMenu2(null)}
            >
                <MenuItem onClick={() => navi('/verification')} >Verification 1</MenuItem>
                <MenuItem><a href="https://spywarecomputers.com/v1/student-verification.php" target="_blank" >Verification 2</a></MenuItem>
            </Menu>
            
            
            <Outlet/>
        </Box>
    );
}