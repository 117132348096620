import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import Back from "../bar";

// MUI
import { Alert, Box, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogActions, DialogContent, Divider, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import {  } from '@mui/icons-material'

export default function StudentMarks({apiglobal, snackbar}){
    const { studentid } = useParams()
    const { courseid } = useParams()

    const [open, setOpen] = useState('')
    const [action, setAction] = useState('')

    useEffect(() => {
        courseDetail()
        getMarks()
    }, [])

    const [course, setCourse] = useState([])
    const courseDetail = () => {
        var formData = new FormData()
        formData.append('get-course-detail', courseid)
        fetch(apiglobal, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            data.status === false ? setCourse(data) : setCourse(data.data)
        })
    }
    const [marks, setMarks] = useState([])
    const [speed, setSpeed] = useState([])
    const getMarks = () => {
        var formData = new FormData()
        formData.append('get-subject-marks', studentid); formData.append('courseid', courseid)
        fetch(apiglobal, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setSpeed(data.typing)
            data.status === false ? setMarks(data) : setMarks(data.data)
        })
    }

    const upload_marks = (event, subjectid) => {
        if(event.keyCode === 13){
            var formData = new FormData()
            formData.append('add-subject-obtained-marks', studentid); formData.append('subjectid', subjectid); formData.append('marks', event.target.value)
            fetch(apiglobal, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                snackbar(data.message); getMarks()
            })
        }
    }

    const delete_marks = () => {
        setAction('deleting')
        var formData = new FormData()
        formData.append('delete-subject-marks', studentid); formData.append('courseid', courseid)
        fetch(apiglobal, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setOpen('')
            setAction('')
            getMarks()
            snackbar(data.message)
        })
    }

    return(
        <Box className='content' >
            <Container><Back text='Student info' />

                <Dialog open={open === 'opendelete' ? true : false} fullWidth maxWidth='xs' onClose={() => setOpen('')} >
                    <DialogContent>
                        <Typography fontWeight={'bold'} fontSize={25} color={'darkorange'} >Do you want to delete marks?</Typography>
                        <Typography fontSize={14} color={'gray'} >Remember, You can't undo this process.</Typography>

                        <Typography fontSize={14} color={'gray'} mt={2} >To delete this marks, type '<i>delete</i>' below.</Typography>
                        <TextField fullWidth variant="outlined" type="text" size="small" placeholder="type 'delete' here" value={action} onChange={(event) => setAction(event.target.value)} onKeyUp={(event) => event.key === 'Enter' && action === 'delete' && delete_marks()} />
                    </DialogContent><Divider/>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => setOpen('')} >Close</Button>
                        <Button variant="contained" color="error" disabled={action === 'delete' ? false : action === 'deleting' ? true : true} onClick={() => delete_marks()} >{action === 'deleting' ? <CircularProgress color="inherit" size={24} /> : 'Delete'}</Button>
                    </DialogActions>
                </Dialog>

                <Grid container spacing={1} >
                    <Grid item xs={12} md={12}>
                        <Card>
                            <CardContent>
                                { course.status === false ? <Alert severity="warning">{course.message}</Alert> :
                                <Box>
                                    <Typography fontSize={35} fontWeight='bold' color='orange' noWrap >{course.c_name}</Typography>
                                    <Typography fontSize={14} color='gray' noWrap >Duration: {course.c_duration} months</Typography>
                                    <Typography fontSize={14} color='gray' noWrap >Course: {course.c_title}</Typography>
                                </Box> }
                            </CardContent>
                        </Card>
                        <Card sx={{mt: 1}} >
                            <CardContent>
                                <Stack direction={'row'} spacing={0.5} useFlexGap flexWrap={'wrap'} >
                                    <Button color="error" variant="contained" onClick={() => setOpen('opendelete')} >Delete marks</Button>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Card>
                            <CardContent>
                                <Box>{ marks.status === false ? <Alert severity="warning">{marks.message}</Alert> :
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>#</TableCell>
                                                    <TableCell align="left">Subject</TableCell>
                                                    <TableCell align="left">Full marks ({marks.status !== false && marks.reduce((total, i) => { return total + Number(i.s_fmark)}, 0)})</TableCell>
                                                    <TableCell align="left">Pass marks ({marks.status !== false && marks.reduce((total, i) => { return total + Number(i.s_pmark)}, 0)})</TableCell>
                                                    <TableCell align="right">Obtained marks ({marks.status !== false && marks.reduce((total, i) => { return total + Number(i.obtained_marks)}, 0)})</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                { marks.map((data, i) =>
                                                <TableRow key={i} >
                                                    <TableCell component="th" scope="row">{i+1}</TableCell>
                                                    <TableCell align="left">{data.s_subject}</TableCell>
                                                    <TableCell align="left">{data.s_fmark}</TableCell>
                                                    <TableCell align="left">{data.s_pmark}</TableCell>
                                                    <TableCell align="right">
                                                        <TextField variant="outlined" placeholder={data.obtained_marks} size="small" onKeyDown={(event) => upload_marks(event, data.subjectid)} />
                                                    </TableCell>
                                                </TableRow>) }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>}
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}