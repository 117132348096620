import React from "react";
import { NavLink, useNavigate } from 'react-router-dom'

// MUI
import { Box, Button, Container, Stack, Typography } from '@mui/material'
import { Call, Instagram, WhatsApp, YouTube } from '@mui/icons-material'

export default function Footer(){
    const navi = useNavigate()

    return(
        <Box sx={{bgcolor: '#1B1B1B'}} pt={3} pb={3} >
            <Container>
                <Box sx={{bgcolor: 'error.main'}} padding={2} mb={3} textAlign={'center'} >
                    <Typography fontSize={25} color={'white'} fontWeight={'bold'} fontFamily={'initial'} >SPYWARE COMPUTER EDUCATION</Typography>
                </Box>
                <Stack direction={{xs: 'column', md: 'row'}} pb={3} sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}} justifyContent={'space-between'} spacing={3} >
                    <Box>
                        <Typography fontSize={16} color={'secondary.main'} fontWeight={'bold'} letterSpacing={2} >SITE MAP</Typography>
                        <Stack direction={'row'} spacing={2} useFlexGap flexWrap={'wrap'} mt={0.5} >
                            <NavLink style={{color: 'white'}} to={'/'} >Home</NavLink>
                            <NavLink style={{color: 'white'}} to={'/verification'} >Verification</NavLink>
                            <NavLink style={{color: 'white'}} >Courses</NavLink>
                            <NavLink style={{color: 'white'}} to={'/contact'} >Contact</NavLink>
                        </Stack>

                        <Typography fontSize={16} color={'secondary.main'} fontWeight={'bold'} letterSpacing={2} mt={3} >LOGIN</Typography>
                        <Stack direction={'row'} spacing={2} useFlexGap flexWrap={'wrap'} mt={0.5} >
                            <NavLink style={{color: 'white'}} to={'/login-admin'} >Admin Login</NavLink>
                            <NavLink style={{color: 'white'}} to={'/login-franchise'} >Franchise Login</NavLink>
                        </Stack>

                        <Typography fontSize={16} color={'secondary.main'} fontWeight={'bold'} letterSpacing={2} mt={3} >Get in touch with us</Typography>
                        <Stack direction={'row'} spacing={2} useFlexGap flexWrap={'wrap'} mt={0.5} >
                            <Button variant="outlined" color="error" startIcon={<YouTube/>} onClick={() => window.location.href = 'https://youtube.com/@spywarecomputers?si=Yk4z9n4dNQu0MCcN'} >YouTube</Button>
                            <Button variant="outlined" color="error" startIcon={<Instagram/>} onClick={() => window.location.href="https://www.instagram.com/spywarecomputers/"} >Instagram</Button>
                            <Button variant="outlined" color="error" startIcon={<WhatsApp/>} onClick={() => window.location.href = 'https://wa.me/917808426759'} >WhatsApp</Button>
                            <Button variant="outlined" color="error" startIcon={<Call/>} onClick={() => window.location.href = 'tel: 7808426759'} >Call</Button>
                        </Stack>
                    </Box>
                    <Box textAlign={{md: 'right', xs: 'left'}} >
                        <Typography fontSize={18} fontWeight={'bold'} color={'error.main'} mb={1} >Take a walk to our office</Typography>
                        <Typography color={'white'} >Mirchaibari road, Katihar - 854105</Typography>
                        <Typography color={'white'} >Bihar, India</Typography>

                        <Typography color={'secondary.main'} mt={3} mb={1} >Join the family of Spywares!</Typography>
                        <Button variant="contained" color="error" sx={{borderRadius: 0, fontWeight: 'bold'}} onClick={() => navi(`/admission`)} >Join Spyware</Button>
                    </Box>
                </Stack>
                <Stack direction={{xs: 'column', md: 'row'}} alignItems={{md: 'center', xs: 'start'}} justifyContent={'space-between'} mt={3} spacing={2} >
                    <Typography color={'white'} fontSize={14} >&#169; copyright spywarecomputers. All rights reserved.</Typography>
                    <a href="https://passionfrom.com" target="_blank"><img src="https://passionfrom.com/passionfromassets/whitePassionfrom.png" style={{width: 120}} /></a>
                </Stack>
            </Container>
        </Box>
    );
}